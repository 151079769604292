/* Joined List */
.joined-list {
    height: 240px;
    margin-right: -15px;
    margin-left: -15px;
}

.joined-list, .joined-list .form-control {
    padding-left: 10px;
    font-size: 13px !important;
}

    .joined-list input {
        margin-right: 1%;
        width: 35%;
    }

.joined-list-container {
    position: relative;
    display: flex;
    padding-top: 1em;
    height: 95%;
    /*height: 20em;*/
}

    .joined-list-container select.form-control {
        width: calc(50% - 35px);
        height: 200px;
        /*display: inline-block !important;*/
    }

        .joined-list-container select option.billable {
            /*border: rgb(52, 73, 40) solid 1px;
            border-radius: 2px;*/
            /*background-color: rgb(133, 187, 101)*/
        }

        .joined-list-container select option.no-decoration {
            /*content: '    ';*/
            padding-left: 1.275em;
        }

        .joined-list-container select option.billable:before {
            content: '$  ';
        }

    .joined-list-container button {
        position: absolute;        
        top: initial;
        width: 38px;
        /*min-width: 38px;*/
    }

        .joined-list-container button .fas {
            margin-left: 0px !important;
        }

        .joined-list-container button:disabled, .joined-list-container button:disabled .fas {
            cursor: not-allowed !important;
        }

        .joined-list-container button:nth-child(1) {
            /*margin-top: 6em;*/
        }

        .joined-list-container button:nth-child(2) {
            /*margin-top: 9em;*/
            margin-top: 35px !important;
        }

    .joined-list-container span {
        cursor: pointer;
    }

.button-container {
    /*position: absolute;*/
    /*left: calc(50% - 30px);*/
    /*top: 35%;*/
    /*display: initial;*/
    width: 60px;
    padding: 66.5px 11px 0px 11px;
    margin-left: 0px !important;
}

    .joined-list .button-container .fas {
        font-weight: 900;
    }

.available-list {
    /*margin-right: 1.3em;*/
}

.selected-list {
    /*margin-left: 10%;*/
}

    .selected-list .new-item {
        background-color: rgba(201, 215, 18, 0.6);
    }
