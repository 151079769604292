.milestone-grid-row {
    display: inline-flex;
    width: 100%;
    padding: 6px 0px 2px 0px;
    border-bottom: 1px solid lightgray;
    line-height: 2;
    /*margin-left: 16.7%;*/
}

.milestone-grid > div:nth-child(2n) {
    /*background-color: rgb(147,158,161);*/
    /*box-shadow: inset 10px 0 10px -10px #000000, inset -10px 0 10px -10px #000000;*/
}

.milestone-grid-row > div {
    /*margin-right: 1%;*/
}

.milestone-grid-row .fa-download {
    transform: rotate(180deg);
    margin-bottom: 0px;
}

    .milestone-grid-row .fa-download input {
        display: none;
    }

.milestone-grid-row.grid-header {
    font-weight: bold;
}

.milestone-grid-row input[type="checkbox"].form-control {
    text-align: left;
    width: 1.4rem;
}

.milestone-dependency-picker {
    position: relative;
}

    .milestone-dependency-picker .popup-dialog {
        min-width: 250px;
        background-color: rgb(234,234,234);
        border-color: transparent rgb(234,234,234) rgb(234,234,234) rgb(234,234,234);
        /*margin-top: 1rem;*/
    }

        .milestone-dependency-picker .popup-dialog:before {
            border-color: transparent rgb(234,234,234) rgb(234,234,234) transparent;
        }

        .milestone-dependency-picker .popup-dialog .milestone-dependency-option {
            padding: 10px 4px;
            display: flex;
        }

            .milestone-dependency-picker .popup-dialog .milestone-dependency-option .dependency-label {
                line-height: 1.5;
                margin-left: 10px;
            }

    .milestone-dependency-picker .fa-edit {
        position: absolute;
        right: 2px;
        left: unset;
        top: -2px;
        font-size: 0.9rem;
        width: 2rem;
    }

        .milestone-dependency-picker .fa-edit:active {
            box-shadow: none;
        }

.milestone-grid-row .switch {
    margin-top: 0.25rem;
}
