/* UserCard styles */

.userCard {
    display: flex;
    position: relative;
}

    .userCard select {
        width: 86%;
        padding: 2px !important;
    }

    .userCard .filterable-select {
        width: calc(100% - 40px);
    }

    .userCard select option {
        padding: 0px !important;
    }

    .userCard span {
        padding-top: 8px;
    }

    .userCard a {
        color: black;
        position: absolute;
    }

        .userCard a:last-of-type {
            right: 0px;
        }

        .userCard a:first-of-type {
            right: 20px;
        }

/*.userCard .fa-envelope {
    cursor: pointer;
}*/

/* End UserCard styles*/

.file-assign-modal {
    width: 50%;
}

.file-assign-header, .file-assign-line {
    display: flex;
}

.file-assign-header {
    padding: 10px 0px;
    border-bottom: 0.5px solid rgba(200,200,200,0.75);
}

.file-assign-line {
    font-size: 12px;
    padding: 3px 0px;
    cursor: default;
}

    .file-assign-line:first-of-type {
        margin-top: 10px;
    }

    .file-assign-line:hover {
        background-color: rgba(50, 50, 50, 0.3);
    }

    .file-assign-line.active {
        background-color: rgba(200,200,200,0.5);
    }


.key-doc-box {
    width: 100%;
    display: block !important;
    background-color: rgb(230, 230, 230);
    padding: 15px;
    min-height: 180px;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
}

    .key-doc-box hr {
        border-color: rgb(100, 100, 100);
        margin: 10px 0px;
    }

.key-doc-line {
    padding: 3px 0px;
    font-size: 13px;
}

    .key-doc-line a {
        cursor: pointer;
    }

    .key-doc-line > div {
        display: inline;
    }

#notepad-editor h4 {
    background-color: inherit !important;
    color: inherit !important;
    padding: 0px !important;
}

.notepad {
    position: relative;
}

.notepad-static-text {
    display: inline-block;
    width: calc(100% - 30px);
    max-width: calc(100% - 30px);
}

.sv-grid-body .notepad-static-text {
    max-width: 8vw;
}

.notepad .fa-pencil-alt {
    position: absolute;
    right: 0px;
    left: unset;
}

    .notepad .fa-pencil-alt:hover,
    .notepad .fa-pencil-alt:active {
        box-shadow: unset;
    }

/* FILTERABLE SELECT */
.filterable-select {
    position: relative;
}

    .filterable-select .default-datalist-items,
    .filterable-select ul {
        max-height: 390px; /* Enough to show 10 rows */
        overflow-y: auto;
        z-index: 1000;
        border: 1px solid lightgray;
        border-top: none;
        border-radius: 3px;
        padding: 0px;
        box-shadow: 0 3px 9px rgba(0,0,0,.25);
        position: absolute;
        width: 100%;
    }

    .filterable-select.grow-upwards ul {
        bottom: 1rem;
    }

    .filterable-select .items {
        position: absolute;
        background-color: white;
        z-index: 3;
        max-height: 300px;
        overflow-y: scroll;
        width: 100%;
        min-width: 175px;
        max-width: 230px;
        border: 1px solid rgb(150, 200, 240);
        /*border-bottom: 1px solid rgb(240, 240, 240);*/
    }

    .filterable-select .select-item,
    .filterable-select .react-datalist-input__container li {
        display: block;
        padding: 8px;
        cursor: pointer;
        z-index: 1001;
        background-color: white;
        font-size: 1em;
    }

        .filterable-select .select-item:hover,
        .filterable-select li:hover {
            background-color: rgb(240, 245, 240)
        }

/* END FILTERABLE SELECT*/

/* HOVER IMAGE PREVIEW*/

.hover-image-preview {
    /*position: fixed;*/
    width: 200px;
    border: 1px solid gray;
}

    .hover-image-preview > img {
        /*max-height: 200px;
        max-width: 200px;*/
        height: 200px;
        width: 200px;
    }

/* END HOVER IMAGE PREVIEW */

/* PROJECT LINKS COMPONENT */

.project-links {
    padding: 15px;
    background-color: rgb(230, 230, 230);
    min-height: 180px;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
}

    .project-links hr {
        border-color: rgb(100, 100, 100);
        margin: 10px 0px;
    }

    .project-links .links-header {
        /*padding: 10px 5px;*/
        position: relative;
    }

        .project-links .links-header span.btn.btn.btn-blue {
            position: absolute;
            right: 10px;
            font-size: 11px;
            padding: 3px 8px;
            top: -5px;
        }

    .project-links .link-row {
        position: relative;
        cursor: pointer;
        padding: 5px;
    }

        .project-links .link-row:hover {
            background-color: rgb(210, 210, 210)
        }

        .project-links .link-row .hover-options {
            position: absolute;
            right: 5px;
            top: -2px;
        }

            .project-links .link-row .hover-options span.btn {
                padding: 3px 6px;
            }

    .project-links .edit-link {
    }

        .project-links .edit-link input {
            border-radius: 3px;
            margin-right: 5px
        }

        .project-links .edit-link .btn {
            font-size: 11px;
            padding: 3px 8px;
            margin-right: 5px;
        }

/* END PROJECT LINKS COMPONENT */

/* POINTED POPUP */

.popup-dialog {
    display: block;
    position: fixed;
    z-index: 5;
    background-color: rgb(219,219,141);
    padding: 8px 5px;
    border-radius: 3px;
    border: 1px solid;
    border-color: transparent rgb(219,219,141) rgb(219,219,141) rgb(219,219,141);
    box-shadow: 0px 5px 5px rgba(0,0,0,0.5);
}

    .popup-dialog:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 5px;
        top: -21px;
        border: 10px solid;
        border-color: transparent transparent rgb(219,219,141) rgb(219,219,141);
        /*box-shadow: 0px 0px 5px black;*/
    }

    .popup-dialog.right-pointer:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: unset;
        right: 5px;
        top: -21px;
        border: 10px solid;
        border-color: transparent rgb(229,229,229) rgb(229,229,229) transparent;
        /*box-shadow: 0px 0px 5px black;*/
    }

    .popup-dialog.gray {
        background-color: rgb(219,219,219);
        border-color: transparent rgb(219,219,219) rgb(219,219,219) rgb(219,219,219);
    }

        .popup-dialog.gray:before {
            border-color: transparent transparent rgb(219,219,219) rgb(219,219,219);
        }

/* END POINTED POPUP*/

/*=================================================================================*/
/* Style the Image Used to Trigger the Modal */
#myImg {
    border-radius: 2px;
    /*cursor: pointer;*/
    /*display: flex;*/
    /*    position: fixed;*/
    /*margin: 0 auto;*/
}

/* The Modal (background) */
.photo-carousel {
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    /*padding-top: 100px;*/ /* Location of the box */
    height: 100vh;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

    .photo-carousel .fas {
        position: fixed;
        font-size: 20px;
        color: rgb(235, 235, 235);
        cursor: pointer !important;
    }

        .photo-carousel .fas:hover {
            color: white;
        }

    .photo-carousel .fa-chevron-left {
        left: 20px;
        top: calc(50% - 10px);
    }

    .photo-carousel .fa-chevron-right {
        right: 20px;
        top: calc(50% - 10px);
    }

    .photo-carousel .fa-times {
        right: 20px;
        top: 20px;
    }

    .photo-carousel .image-container {
        position: fixed;
        overflow-y: auto;
    }

    .photo-carousel img {
        width: 100%;
        max-width: 800px;
    }

    .photo-carousel label {
        position: fixed;
        font-size: 20px;
        color: white;
    }
/*==================================================*/

/* MODAL STYLES */

.modal.show {
    display: block;
}

.modal.double-top-margin .modal-dialog {
    margin-top: 3.5rem;
}

.modal.triple-top-margin .modal-dialog {
    margin-top: 5.25rem;
}

.modal.half-width .modal-dialog {
    width: 25%;
}

.modal.show .modal-dialog {
    max-width: 50%;
    animation: modalSlideDown 0.3s ease-in-out;
}

.modal.show.no-animation .modal-dialog {
    animation: none;
}

.modal .modal-content {
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
}

@keyframes modalSlideDown {
    0% {
        margin-top: 0px;
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*==================================================*/

/* COMMENT BOX */

.comment-box {
    padding: 8px 0px;
    position: relative;
    margin: 8px 0px;
}

    .comment-box .commenter-name {
        position: absolute;
        right: 5px;
        top: -9px;
        font-size: 11px;
    }

    .comment-box .comment {
        padding: 10px;
        background-color: rgb(230, 230, 230);
        border-radius: 2px;
        white-space: pre-line;
    }

/*==================================================*/

/* CLIENT INFO FORM STYLES*/

.client-info-form .form-group,
.vendor-info-form .form-group {
    /*width: 50%;*/
    display: flex;
}

    .client-info-form .form-group input,
    .vendor-info-form .form-group input,
    .client-info-form .form-group > select,
    .vendor-info-form .form-group > select {
        width: calc(100% - 150px);
    }

    .client-info-form .form-group .inline-label,
    .vendor-info-form .form-group .inline-label {
        width: 150px;
        line-height: 2.5;
    }
/*==================================================*/

/* CLIENT INFO MOADL STYLES*/

.client-info-link > span,
.vendor-info-link > span {
    cursor: pointer;
    text-decoration: underline;
}

/*==================================================*/

/* === BACK LINK === */

.back-link {
    width: 66px;
    margin-top: 5px;
    padding: 6px 8px;
    font-size: 12px;
    line-height: 1.3;
}

/* ================= */

/* ===== EVENT LOGS ===== */

.event-history-log {
    max-height: 80vh;
    overflow-y: auto;
}

    .event-history-log .event-log-tile {
        margin: 5px 0px 10px 0px;
        padding: 8px 10px;
        border: 1px solid lightgray;
        background-color: rgb(245, 245, 245);
        /*box-shadow: rgba(0,0,0,0.2) 0px 0px 5px;*/
        border-radius: 2px;
    }

    .event-history-log hr {
        margin: 5px 0px;
    }

    .event-history-log .event-log-tile .tile-header {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

        .event-history-log .event-log-tile .tile-header .event-date {
            /*position: absolute;*/
            /*right: 0px;*/
            /*margin-left: auto;*/
        }

/* ====================== */

/* === KPI Report Modal === */

.cm-kpi-report .form-group {
    width: 15vw;
    margin-right: 10px;
}

.cm-kpi-report .clear-filters-button {
    line-height: 1.1;
    height: 2.25em;
    margin-top: 1.5em;
}

.cm-kpi-report .grid .grid-body {
    max-height: 40vh;
}

/* ======================== */

/* === UPDATE ANNOUNCEMENTS === */

.update-announcement-body .announcement-list {
    width: 260px;
    background-color: #636569;
    border-radius: 3px;
}

.update-announcement-body .announcement-title {
    font-size: 1.1em;
    /*text-decoration: underline;*/
    margin-top: 10px;
    width: 230px;
    margin-left: 15px;
    padding: 6px 0px;
    text-align: center;
    background-color: #353536;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

    .update-announcement-body .announcement-title.active {
        background-color: #009ade;
    }

    .update-announcement-body .announcement-title:hover {
        background-color: #353536;
        color: #ff9e18;
    }

.update-announcement-body .announcement-page-content {
    width: calc(100% - 270px);
    margin-left: 10px;
    position: relative;
}

    .update-announcement-body .announcement-page-content .content-container {
        height: 500px;
        overflow-y: auto;
        width: calc(100% - 50px);
        margin-left: 25px;
    }

    .update-announcement-body .announcement-page-content img {
    /*    width: calc(100% - 50px);
        margin-left: 25px;*/
        /*left: 0;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        position: absolute;*/
        /*overflow-y: auto;*/
    }


    .update-announcement-body .announcement-page-content .fas {
        position: absolute;
        top: calc(50% - 0.5em);
        color: gray;
    }

    .update-announcement-body .announcement-page-content .fa-chevron-left {
        left: 10px;
        font-size: 1.8em;
    }

    .update-announcement-body .announcement-page-content .fa-chevron-right {
        right: 10px;
        font-size: 1.8em;
    }

    .update-announcement-body .announcement-page-content .page-indicators {
        position: absolute;
        bottom: 10px;
        display: flex;
        justify-content: space-around;
        column-gap: 10px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        /*margin-top: -30px;*/
    }

    .update-announcement-body .announcement-page-content .page-indicator {
        border: 2px solid gray;
        width: 10px;
        height: 10px;
        border-radius: 5px;
    }

        .update-announcement-body .announcement-page-content .page-indicator.active {
            background-color: gray;
        }


/* ============================ */
