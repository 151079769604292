
.custom-report-configuration .configuration-body {
    max-height: 80vh;
    overflow-y: auto;
    animation: configSlideDown 0.6s ease-in-out;
    margin-bottom: 20px;
}

.custom-report-configuration.collapsed .configuration-body {
    max-height: 0px;
    animation: configSlideUp 0.6s ease-in-out;
    border-bottom: none;
    margin-bottom: 0px
}

.custom-report-configuration .sv-grid-line,
.custom-report-configuration .sv-grid-header {
    padding-left: 0px;
    padding-right: 0px;
}

.report-validation-message {
    border-radius: 3px;
    padding: 10px 0px;
    text-align: center;
    background-color: rgba(200, 50, 50, 0.5);
    margin-bottom: 10px;
    font-size: 1.1em;
}

    .report-validation-message > div {
        margin-bottom: 8px;
    }

    .report-validation-message > div:last-of-type {
        margin-bottom: 0px;
    }

@keyframes configSlideDown {
    0% {
        max-height: 0px;
        opacity: 0;
        /*padding: 0px 8px;*/
    }

    100% {
        max-height: 80vh;
        opacity: 1;
        /*padding: 8px 8px;*/
    }
}

@keyframes configSlideUp {
    0% {
        max-height: 80vh;
        margin-bottom: 20px;
        opacity: 1;
        /*padding: 0px 8px;*/
    }

    100% {
        max-height: 0vh;
        margin-bottom: 0px;
        opacity: 0;
        /*padding: 8px 8px;*/
    }
}
