.token-list .btn {
    font-size: 12px;
    padding-top: 0px;
}

.token-list-header {
    padding-bottom: 4px;
    border-bottom: 1px solid gray;
}


.token-item {
    padding: 6px 0px;
    line-height: 2;
}

    .token-item:nth-child(2n+1) {
        background-color: rgb(240, 240, 240);
    }

    .token-item select.form-control,
    .token-item option.form-control {
        padding: 2px 0px !important;
        height: 28px;
        margin-top: -2px;
        margin-left: -3px;
    }

.token-users {
    padding-top: 8px;
    margin-bottom: 8px;
    background-color: rgb(230, 240, 250);
    margin-left: calc(25% - 5px);
}

.token-user-header {
    padding-bottom: 4px;
    border-bottom: 1px solid gray;
}
   
.token-user-line {
    padding: 6px 0px;
    line-height: 2;
}

    .token-user-line:nth-child(2n+1) {
        background-color: rgb(220, 230, 240);
    }