.procurement-grid .grid-body {
    overflow: visible;
}

.procurement-modal > .modal-dialog {
    width: 1200px;
    max-width: 1200px !important;
}

.procurement-modal .modal-body:first-child {
    max-height: 75vh;
    overflow-y: auto;
}

.quote-modal .modal-dialog {
    width: calc(50% - 150px);
}

.quote-modal .modal-body {
    max-height: 50vh;
    overflow-y: auto;
}

.procurement-modal .modal-body {
    max-height: 75vh;
    overflow-y: auto;
}

.procurement-comments-modal .modal-dialog {
    width: 800px;
}

.procurement-comments-modal .modal-body {
    /* max-height: 55vh;
    overflow-y: auto;*/
}

.quote-detail,
.procurement-detail {
    display: flex;
}

    .quote-detail .quote-form,
    .procurement-detail .procurement-form {
        width: 70%;
        padding-left: 5px;
        padding-right: 10px;
        padding-bottom: 5px;
        overflow-y: auto;
    }

    .quote-detail .quote-history-logs,
    .procurement-detail .procurement-history-logs {
        width: 30%;
        padding-left: 10px;
        border-left: 1px solid gray;
    }

        .procurement-detail .procurement-history-logs .event-history-log,
        .modal-body .procurement-detail .procurement-form {
            max-height: calc(75vh - 2rem);
        }

.quote-form .form-group, .procurement-form .form-group {
    display: flex;
    width: 100%;
    margin: 4px 0px;
    line-height: 2;
}

    .quote-form .form-group label,
    .procurement-form .form-group label {
        width: 220px;
        font-weight: bold;
        line-height: 2;
    }

    .quote-form .form-group > input,
    .quote-form .form-group > textarea,
    .quote-form .form-group .filterable-select,
    .procurement-form .form-group > input,
    .procurement-form .form-group > textarea,
    .procurement-form .form-group > .filterable-select {
        width: calc(100% - 220px);
    }



.procurement-item-line {
    display: flex;
}

    .procurement-item-line input.form-control {
        padding: 2px 4px !important;
    }

.quote-line {
    position: relative;
    padding: 0px 0px 10px 0px;
}

    .quote-line > div {
        width: calc(100% - 25px);
    }

    .quote-line .btn {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .quote-line .fa-check {
        color: rgb(0, 210, 15);
    }

    .procurement-item-line .btn-x-sm,
    .quote-line .btn-x-sm {
        height: 23px !important;
        /*width: 23px !important;*/
        font-size: 12px !important;
        padding: 3px 5px !important;
    }


.procurement-detail .reviewer-line,
.procurement-item-line .reviewer-line {
    display: block;
    position: relative;
    padding: 0px 0px 0px 0px;
}

.quote-line,
.reviewer-line {
    min-height: 1.5em;
}

    .quote-line .btn-x-sm,
    .reviewer-line .btn-x-sm {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .reviewer-line.add-quote-component .btn-x-sm:first-of-type {
        right: 57px;
    }

    .reviewer-line.add-quote-component .filterable-select {
        width: calc(100% - 100px);
    }

        .reviewer-line.add-quote-component .filterable-select input {
            height: 2em !important;
            padding: 0px 4px !important;
        }

            .reviewer-line.add-quote-component .filterable-select input::-webkit-calendar-picker-indicator {
                margin-top: -5px;
            }

.procurement-detail .reviewer-line .fas,
.procurement-item-line .reviewer-line .fas {
    position: absolute;
    right: 0px;
    top: 5px;
}

.procurement-detail .reviewer-line,
.procurement-detail .quote-line {
    width: 100%;
}

.quote-approval-modal .modal-dialog,
.quote-approval-modal .modal-content {
    width: 500px;
}
