.dashboard-body {
    padding: 10px 0px;
}

    .dashboard-body .dashboard-filters {
        min-width: 200px;
    }

    .dashboard-body > div {
        width: 100%;
    }

        .dashboard-body > div:not(:first-child) {
            margin-top: 30px;
        }

    .dashboard-body .dashboard-filters .date-range-picker {
        width: 185px;
    }

        .dashboard-body .dashboard-filters .date-range-picker input {
            padding: 5px 6px;
            width: calc(100% - 11px);
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

    .dashboard-body .dashboard-kpis > div.my-col-3 {
        min-width: 160px;
    }

.kpi {
    padding: 15px 15px;
    border: 2px solid lightgray;
    border-radius: 3px;
    text-align: center;
    box-shadow: 0px 7px 10px rgba(200, 200, 200, 0.1);
    min-width: fit-content;
    width: 100%;
    margin: 0px 6px;
}

    .kpi.small {
        padding: 7px 12px;
        border: 1px solid lightgray;
        max-width: 150px;
    }

        .kpi.small hr {
            margin: 10px 0px;
        }

    .kpi .kpi-label p {
        font-weight: bold;
        white-space: nowrap;
    }

    .kpi .kpi-value p {
        font-size: 2em;
    }

    .kpi.small .kpi-value p {
        font-size: 1.5em;
    }

.activity-stream {
    /*padding: 15px 20px;*/
    /*border: 2px solid lightgray;*/
    border-radius: 3px;
    overflow: auto;
}

    .activity-stream hr {
        margin: 20px 0px 10px 0px;
    }

    .activity-stream .stream-header {
        font-weight: bold;
        padding: 10px 0px 20px 0px;
    }

        .activity-stream .stream-header span.fas {
            float: right;
            cursor: pointer;
        }

    .activity-stream .activity-line {
        display: flex;
        min-width: 800px;
    }

    .activity-stream .activity-line, .activity-stream .filter-row {
        padding: 10px 0px;
        font-size: 12px;
    }

.activity-line:nth-of-type(2n) {
    background-color: rgb(225, 230, 230);
}

.activity-stream .filter-row input {
    width: 85%;
}


/* GRAPH STYLES */
.dashboard-graphs {
    padding: 0px 15px;
}

    .dashboard-graphs .header {
        display: flex;
        position: relative;
        width: 100%;
        min-height: 70px;
        border: 1px solid gray;
        border-radius: 3px;
        box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.3);
        padding: 10px;
    }

        .dashboard-graphs .header .filters,
        .graph-part .filters {
            width: calc(100% - 150px);
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(160px, 180px));
            grid-gap: 10px;
        }

    .dashboard-graphs .small-header {
        height: 2rem;
    }

        .dashboard-graphs .small-header .btn {
            float: right;
        }

.graph-part .filters {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(160px, 23%));
}

.dashboard-graphs .header .filters {
    margin-left: 15px;
    min-height: 57px;
}

.graph-part .filters,
.graph-part .graph-part-options,
.graph-part .graph-part-options .form-control {
    font-size: 11px !important;
}

    .graph-part .graph-part-options .form-control,
    .graph-part .filters .form-control {
        padding: 2px 4px !important;
        height: 1.5rem;
    }

.graph-part .user-filter {
    height: 50px;
}

.dashboard-graphs .header .filters > div,
.graph-part .user-filter {
    /*            margin: 0px 0px 0px 15px;*/
    position: relative;
}

    .dashboard-graphs .header .filters label,
    .graph-part .user-filter label {
        margin-bottom: 0px;
    }

.dashboard-graphs .header .active-filter {
    min-width: 150px;
    position: relative;
}

    .dashboard-graphs .header .active-filter p {
        margin-bottom: 0.5rem;
    }

    .dashboard-graphs .header .filters .fa-times,
    .dashboard-graphs .header .active-filter .fa-times,
    .graph-part .user-filter .fa-times {
        position: absolute;
        right: 0px;
        top: 0px;
    }

.dashboard-graphs .header .header-buttons {
    position: absolute;
    right: 15px;
    top: 8px;
}

    .dashboard-graphs .header .header-buttons .btn {
        display: block;
        width: 95px;
        margin-bottom: 4px;
        font-size: 12px;
    }

.dashboard-graphs .header .btn-outline-secondary {
    height: 28px;
    margin-left: 8px;
    font-size: 12px;
    width: 100px;
}

    .dashboard-graphs .header .btn-outline-secondary:first-of-type {
        margin: -2px 0px 4px 8px;
    }

.dashboard-graphs .graphs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    grid-gap: 1.5rem;
    padding-top: 1.5rem;
    width: 100%;
}

    .dashboard-graphs .graphs-container .graph-part {
        flex-basis: 700px;
        flex-grow: 1;
    }

    .dashboard-graphs .graphs-container .graph-part.kpi-graph {
        /*width: 50%;*/
    }

    .graph-part .graph-kpi-value  {
        font-size: 10vh;
        text-align: center;
        padding-top: calc(200px - 1em);
        color: rgb(50, 50, 50);
        height: 400px;
    }

.graph-part {
    border: 1px solid gray;
    border-radius: 3px;
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.3);
    padding: 10px 15px;
    position: relative;
    /*    max-width: fit-content;*/
}

    .graph-part .graph-part-options {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
    }

        .graph-part .graph-part-options.table-options {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(137px, 23%));
            grid-gap: 10px;
        }

        .graph-part .graph-part-options > div {
            width: 23%;
            margin-right: 10px;
        }

        .graph-part .graph-part-options.table-options > div {
            width: 100%;
            margin-right: 0px;
        }

        .graph-part .filters .fa-save {
            width: 2.3em;
            height: 2.3em;
            margin-top: 1.25em;
        }

    .graph-buttons {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 13px;
    }

        .graph-buttons > * {
            margin: 0px 5px
        }

    /*.graph-part .fa-trash-alt {
        position: absolute;
        right: 14px;
        top: 10px;
        font-size: 13px;
    }

    .graph-part .fa-share-alt {
        position: absolute;
        right: 37px;
        font-size: 13px;
        top: 10px;
        cursor: pointer;
    }

    .graph-part .fa-filter {
        position: absolute;
        right: 60px;
        top: 10px;
        font-size: 13px;
        cursor: pointer;
    }

    .graph-part .fa-download {
        position: absolute;
        right: 83px;
        top: 10px;
        font-size: 13px;
        cursor: pointer;
        color: black;
    }*/

    .graph-part .graph-part-options .fa-times {
        float: right;
        margin-top: 3px;
        margin-right: 5px;
    }

    .graph-part .graph-part-options .fa-plus {
        position: absolute;
        right: 91px;
        top: -32.5px;
        font-size: 13px;
    }

    .graph-part .title-edit {
        display: flex;
        width: calc(50% + 32px);
        margin-bottom: 5px;
    }

        .graph-part .title-edit input {
            display: inline-block;
            margin-right: 10px;
        }

    .graph-part .graph-part-options > div select {
        /*        margin-top: 5px;*/
        padding: 3px !important;
        height: 1.7rem;
    }

        .graph-part .graph-part-options > div select > option.form-control {
            font-size: 14px;
        }

    .graph-part h5 .fas {
        top: -9px;
        margin-left: 3px;
    }

    .graph-part h5 .fa-edit:hover {
        box-shadow: none;
    }

.google-visualization-table {
    width: 100%;
    margin: auto;
}    

.google-visualization-table-tr-head .google-visualization-table-th {
    min-width: 150px;
}

.google-visualization-table-tr-even, .google-visualization-table-tr-odd {
    text-align: center;
}
/* END GRAPH STYLES */
