.grid-header {
    border-bottom: 2px solid lightgray;
    border-radius: 3px;
    display: flex;
    padding: 7px 0px;
    flex-wrap: wrap;
}

    .grid-header .sortable {
        cursor: pointer;
    }

.grid-filter {
    display: flex;
    padding: 0px 0px;
}

.grid .grid-line {
    display: flex;
    padding: 7px 0px;
    border-bottom: 1px solid lightgray;
}

.grid .centered, .centered {
    text-align: center;
}

.grid input.form-control {
    padding: 3px 6px !important;
    height: calc(1.25em + 0.75rem + 2px) !important;
}

.grid .grid-body {
    max-height: 65vh;
    overflow: auto;
}


.sv-grid {
    width: 100%;
}

    .sv-grid .sv-grid-header {
        display: flex;
        padding: 8px;
        position: relative;
        border-bottom: 2px solid rgb(180, 180, 180);
    }

    .sv-grid .sv-grid-filter {
        position: relative;
    }

        .sv-grid .sv-grid-header .sv-grid-header-buttons,
        .sv-grid .sv-grid-filter .sv-grid-filter-buttons {
            position: absolute;
            right: 10px;
            top: 0px;
            display: flex;
        }

            .sv-grid .sv-grid-filter .sv-grid-filter-buttons .fas {
                right: 2px;
                top: -2px;
            }

            .sv-grid .sv-grid-header .sv-grid-header-buttons .fas {
                margin-right: 7px;
                margin-top: 0.75em;
            }

            .sv-grid .sv-grid-header .sv-grid-header-buttons .fa-plus,
            .sv-grid .sv-grid-header .sv-grid-header-buttons .fa-file-certificate {
                margin-top: 0.25em;
            }

            .sv-grid .sv-grid-header .sv-grid-header-buttons .fa-file-certificate {
                margin-right: 4px;
            }

    .sv-grid .sv-grid-line {
        padding: 8px;
        display: flex;
        position: relative;
        border-bottom: 1px solid lightgray;
        min-height: calc(1.5em + 14px);
        cursor: pointer;
    }

        .sv-grid .sv-grid-line.color-tab {
            border-radius: 10px 0px 0px 10px;
            margin-left: -10px;
        }

        .sv-grid .sv-grid-line.sv-yellow-tab {
            border-left: solid 10px rgba(244, 208, 63, 0.8);
        }

        .sv-grid .sv-grid-line.sv-blue-tab {
            border-left: solid 10px rgba(27, 87, 230, 0.8);
        }

        .sv-grid .sv-grid-line.sv-green-tab {
            border-left: solid 10px rgba(0, 150, 0, 0.8);
        }

        .sv-grid .sv-grid-line.sv-red-tab {
            border-left: solid 10px rgb(217, 83, 79);
        }

        .sv-grid .sv-grid-line.sv-purple-tab {
            border-left: solid 10px rgba(170, 97, 230, 0.8);
        }

        .sv-grid .sv-grid-line:hover {
            background-color: rgba(100, 150, 120, 0.2);
        }

        .sv-grid .sv-grid-line.no-hover {
            cursor: default;
        }

            .sv-grid .sv-grid-line.no-hover:hover {
                background-color: inherit;
            }

        .sv-grid .sv-grid-line .sv-grid-buttons {
            position: absolute;
            right: 10px;
            display: flex;
        }

            .sv-grid .sv-grid-line .sv-grid-buttons .btn {
                margin-left: 10px;
            }



    /* === DateRange Picker === */

    .sv-grid .date-range-picker .form-control {
        background-color: unset;
    }

    .sv-grid .date-range-picker .fa-times {
        position: absolute;
        right: 7px;
        top: 0.4em;
    }

/* ======================== */
