.notification-tray {
    position: relative;
    width: 45px;
}

    .notification-tray .fa-inbox {
        z-index: 999;
        /*position: absolute;*/
    }

    .notification-tray .tray-button {
        position: relative;
        width: 1em;
    }

    .notification-tray .notification-counter {
        position: absolute;
        /*float: right;*/
        /*transform: translateX(-15px);*/
        font-size: 11px;
        top: -0.65em;
        /*width: 1.5em;*/
        padding: 0px 3px;
        height: 1.5em;
        border-radius: 0.375em;
        color: white;
        background-color: red;
        right: -1em;
        min-width: 14px;
        text-align: center;
        vertical-align: central;
        z-index: 0;
    }

    .notification-tray .notification-stream {
        position: absolute;
        font-size: 11px;
        /*border: 1px solid #002e6d;*/
        border-top: none;
        box-shadow: rgba(0,0,0,0.3) 0px 0px 10px;
        border-radius: 3px 0px 3px 3px;
        background-color: white;
        color: gray !important;
        width: 400px;
        left: calc(-400px + 45px);
        top: 50px;
        max-height: 50vh;
        overflow-y: auto;
        z-index: 3;
    }

        .notification-tray .notification-stream.empty-stream {
            width: 250px;
            left: calc(-250px + 45px);
            text-align: center;
        }

            .notification-tray .notification-stream.empty-stream .notification-item:hover {
                background-color: unset;
            }

        .notification-tray .stream-pointer {
            position: absolute;
            right: -4px;
            top: calc(35px - 0.9em);
            width: 0;
            height: 0;
            border: 0.7em solid transparent;
            border-top: 0;
            border-bottom: 0.9em solid white;
            z-index: 100;
        }

.notification-stream .notification-item {
    position: relative;
    padding: 10px 30px 10px 20px;
}

    .notification-stream .notification-item.unread {
        background-color: rgba(0,20,150,0.07);
    }

.notification-stream .notification-item,
.notification-tray .stream-tray-banner {
    /*display: flex;*/
    position: relative;
    border-bottom: 1px solid lightgray;
    /*display: inline-block;*/
    cursor: default;
}

.notification-tray .stream-tray-banner,
.notification-tray .stream-tray-footer {
    /*text-align: center;*/
    padding: 5px;
}

    .notification-tray .stream-tray-banner .banner-links {
        float: right;
    }

        .notification-tray .stream-tray-banner .banner-links a {
            margin: 0px 4px;
        }

/*.notification-stream .notification-item:hover,
        .notification-tray .stream-tray-banner:hover,
        .notification-tray .stream-tray-footer:hover {
            background-color: rgba(0,0,20,0.1);
        }*/

.notification-stream .notification-item:hover {
    background-color: rgba(0,20,150,0.2);
}

.notification-tray .notification-stream a,
.notification-stream .notification-item a {
    color: #0366d6 !important;
    display: inline-flex;
    padding: 0px;
    height: unset;
}

.notification-stream .notification-item .notification-timestamp {
    font-size: 9px;
    color: black;
    /*float: right;*/
    /*display: block;*/
    /*position: absolute;*/
    /*right: 10px;*/
    /*top: 5px;*/
}

.notification-stream .notification-item .fa-times {
    position: absolute;
    right: 10px;
    top: calc(50% - 0.5em);
}

    .notification-stream .notification-item .fa-times:hover {
        font-size: 1.1em;
    }


.my-notifications-page .notification-stream {
    margin: 10px;
    width: 50%;
}
