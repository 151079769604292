/* TABS STYLING */

.tabs {
    width: 400px;
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
    padding-left: 30px;
}

.tabs-1 .tab-links, .tab-links {
    margin: 0;
    padding: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 35px;
    position: relative;
    top: 5px;
    padding-left: 15px;
    z-index: 2;
    display: inline-block;
}
  
    .tab-links.bordered {
        background-color: rgb(230,230,230);
        border: solid 0.5px rgb(200,200,200);
        border-radius: 5px;
        height: 35px;
        padding-left: 0px;
        display: block;
        border-radius: 5px;
    }

.tab-groups {
    display: inline-block;
}

.tab-group {
    padding: 0 5px;
    cursor: pointer;
    /*border: 1px solid transparent;*/
    /*background: transparent;*/
    display: inline-block;
    position: relative;
    border-radius: 5px 5px 0px 0px;
    clear: both;
    margin-left: 10px;
    /*height: 2em;*/
    /*line-height: 2em;*/
    /*top: -4px;*/
    /*left: 0px;*/
    /*outline: none;*/
    /*border: none;*/
    /*border-bottom: 2px solid transparent;*/
    /*font-weight: bold;*/
}

    .tab-group .group-label {
        padding: 10px 15px 10px 15px;
        font-weight: bold;
        font-size: 16px;
        /*height: 2em;*/
        /*line-height: 2em;*/
    }

    .tab-group:hover {
        /*background-color: rgb(0, 99, 66);*/
        background-color: #777; 
        color: white;
    }

    .tab-group .tab-group-pointer {
        position: absolute;
        left: 4px;
        top: -14px;
        width: 0;
        height: 0;
        border: 0.7em solid transparent;
        border-top: 0;
        border-bottom: 0.9em solid white;
        z-index: 100;
    }

    .tab-group .group-links {
        padding: 0px 0px;
        position: absolute;
        left: 0px;
        margin-top: 0px;
        z-index: 10;
        /*border: 1px solid lightgray;*/
        box-shadow: rgba(0,0,0,0.3) 0px 10px 10px;
        /*background-color: rgb(0, 99, 66);*/
        background-color: #777;
        color: white;
        border-radius: 0px 5px 5px 5px;
    }

        .tab-group .group-links .tab-link {
            font-size: 1em;
            font-weight: 100;
            padding: 8px 60px 8px 25px;
            height: unset;
            line-height: unset;         
            left: 0.5px;
            z-index: 10;
            min-width: 300px;
        }

            .tab-group .group-links .tab-link > span {
                padding-bottom: 2px;
                background-image: linear-gradient(#fff 0 0);
                background-position: 0 100%; /*OR bottom left*/
                background-size: 0% 2px;
                background-repeat: no-repeat;
                transition: background-size 0.4s, background-position 0s 0.4s; /*change after the size immediately*/
            }

            .tab-group .group-links .tab-link:first-of-type {
                padding-top: 12px;
/*                border-top: 1px solid gray;
*/            }

            .tab-group .group-links .tab-link:hover {
                /*font-weight: bold;*/
                background-color: white;
                color: black;
            }

            .tab-group .group-links .tab-link:hover > span {
                background-position: 100% 100%; /*OR bottom right*/
                background-size: 100% 2px;
                background-image: linear-gradient(#000 0 0);
            }

.tab-link {
    padding: 0 15px;
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
    display: inline-block;
    position: relative;
    height: 35px;
    line-height: 35px;
    top: -4px;
    left: -1px;
    outline: none;
    /*border: none;*/
    border-bottom: 2px solid transparent;
}

    .tab-links.left-align .tab-link {
        padding: 0px;
        margin-right: 15px;
    }


    .tab-group .tab-link {
        display: block;
    }


    .tab-links.bordered .tab-link {
        top: -1px;
    }

        .tab-links.bordered .tab-link:hover {
            background-color: rgb(150, 150, 150);
            /*border: rgb(175, 175, 175) solid 0.5px;*/
        }

.content {
    padding: 10px;
    margin-bottom: 20px;    
    /*border: 1px solid #868686;*/
    /*border-top: 1px solid transparent;*/
}

    .content.bordered {
        border: solid 0.5px rgb(200,200,200);
        display: flow-root;
        min-height: 60vh;
    }

.content.extra-padding {
    padding-top: 25px;
    padding-left:40px;
}

/* END TABS STYLING*/
