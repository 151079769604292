body {
	position: relative;
	font-family: "Montserrat", "Source Sans Pro", "Arvo";
	font-size: 12px;
	/*background-color: #F8F8FD*/
}

/** {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}*/

footer {
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding-bottom: 10px;
}

footer > div {
	font-size: 8px;
	padding-left: 45%;
}

#root {
	overflow-y: hidden;
}

#react-app {
	min-height: 100vh;
}

.container-fluid {
	padding: 0px !important;
}

#loginForm {
	padding: 30px;

}

#loginForm hr {
	width: 100%;
}

#loginForm .form-group {
	width: 100%;
}

.login-page input[type="radio"] {
	margin: 0px 10px 10px 0px !important;
	line-height: 1;
	/*vertical-align: bottom;*/
}

input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	display: none;
}

::-webkit-calendar-picker-indicator {
	margin-left: 0px;
}

input.form-control,
select.form-control,
textarea.form-control {
	font-size: 12px !important;
	padding: 6px !important;
}

#editing-checklist-section input.form-control {
	font-size: 1.25rem !important;
}

select.form-control {
	padding: 1px !important;
}

input.form-control,
select.form-control {
	height: calc(1rem + 12px);
}

.mobile-show {
	display: none;
}

.app-body {
	/*margin-top: 50px;*/
	/*padding: 0px !important;*/
	max-height: calc(100vh - 25px);
	/*margin-bottom: 50px;*/
	height: auto !important;
	width: 100%;
	transition: width 2.4s;
	/*transition-delay: 2s;*/
	/*animation: slideWidthIn 0.4s ease-in-out forwards;*/
	/*overflow: auto;*/
}

.app-body:after {
	content: "";
	display: block;
	height: 50px;
}

.fas {
	cursor: pointer;
}

.form-footer {
	margin: 10px 0px;
}

.warning-note {
	padding: 10px;
	border-radius: 5px;
	background-color: rgba(255, 215, 97, 0.7);
}

.no-overflow {
	overflow: hidden;
}

.pageHeader {
	/*height: 42px;*/
	min-height: 42px;
	position: relative;
	font-size: 26px;
	/*padding: 6px 12px;*/
	margin: 10px 0px 0px 0px;
	/*background-color: rgba(200, 200, 230, 0.75);*/
	/*background-color: rgb(11,214,190);*/
	/*background-color: rgba(0,20,40,0.5);*/
	/*color: white;*/
	/*border-radius: 3px;*/
	overflow: hidden;
}

.pageHeader .header-btn-right {
	position: absolute;
	right: 15px;
	top: 20%;
}

.pageHeader p {
	font-size: 28px !important;
	margin: 0px;
}

.pageHeader p a {
	margin-left: 15px;
	font-size: 13px !important;
	vertical-align: central;
}

.filter-section {
	display: flex;
	/*margin-left: 32px;*/
}

.tile {
	padding: 12px;
	margin: 0px 12px 12px 0px;
	border: 1px solid lightgray;
	border-radius: 3px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.box-shadow-card {
	border-radius: 5px;
	-webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}

.alert-ribbon {
	background-color: rgba(230, 97, 97, 0.8);
	padding: 10px 10px 5px 10px;
	border-radius: 5px;
	color: white;
}

.flex-row {
	display: flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	padding: 0px !important;
	margin-left: 15px !important;
}

.flex-row.no-margin {
	margin-left: 0px !important;
}

.flex-row > .row {
	margin-bottom: 20px;
	padding-right: 5px !important;
}

.flex-columns {
	flex-direction: column;
}

.flex-columns > div {
	width: 40%;
	display: inline-flex;
}

.flex-columns > div input[type="checkbox"],
.flex-columns > div input[type="radio"] {
	margin-right: 8px;
}

.link {
	cursor: pointer;
	text-decoration: underline;
	color: rgb(0, 25, 180);
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

.inline-block {
	display: inline-block;
}

/*.filter-section input {
    width: 15%;
}*/

/*.filter-section > div {
    justify-content: flex-end
}*/

.flex-columns {
	flex-direction: column;
}

.flex-columns > div {
	width: 40%;
	display: inline-flex;
}

.flex-columns > div input[type="checkbox"],
.flex-columns > div input[type="radio"] {
	margin-right: 8px;
}

.report-table .filters-row {
	padding: 0px 10px;
}

.report-table .filters-row input {
	border-radius: 2px;
	padding: 2px 6px;
	width: 100%;
}

.report-table .filters-row .date-range-picker input {
	width: calc(100% - 11px);
}

.filters-row input.form-control {
	font-size: 10px !important;
}

.filters-row .btn.fas {
	top: -0.5px;
	font-size: 10px;
	padding: 8px 10px;
}

.fa-exclamation-triangle {
	color: rgb(230, 97, 97) !important;
}

.detail-link {
	cursor: pointer;
	/*padding: 5px;*/
}

.detail-link:hover {
	/*color: #333;*/
	/*background-color: #e6e6e6;*/
	/*border-color: #8c8c8c;*/
	/*outline: 5px auto -webkit-focus-ring-color;*/
	/*outline-offset: -2px;*/
}

.detail-link:active {
	/*color: #333;*/
	/*background-color: #e6e6e6;*/
	/*border-color: #adadad;*/
}

a.detail-link {
	/*color: inherit;*/
	text-decoration: underline;
}

/*.project-row .col-sm-1, .project-row .col-sm-1-plus, .project-row .col-sm-2, .project-row .col-sm-tiny {*/
.col-sm-1,
.col-sm-1-plus,
.col-sm-2,
.col-sm-tiny,
.col-sm-x-tiny,
.col-sm-1-minus,
.col-sm-3 {
	padding-left: 10px !important;
	padding-right: 5px !important;
}

.project-row .btn-sm {
	font-size: 11px;
}

.milestone-grid {
	cursor: auto;
	/*width: 95%;*/
	/*background-color: whitesmoke;*/
	/*margin-left: 16.7%;*/
	padding: 10px 0px 10px 0px;
	font-size: 12px;
	transition: height 3s;
	/*-moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;*/
}

.project-row .milestone-grid {
	margin-left: 40px;
}

.milestone-grid hr {
	margin: 10px 0px;
}

.material-grid {
	font-size: 13px;
	padding: 15px;
	overflow: auto;
	/* -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;*/
}

.material-grid-row {
	padding: 5px 0px;
}

.material-grid-row input.form-control,
.material-grid-row select.form-control {
	font-size: 11px !important;
	padding: 4px !important;
}

.photo-viewer {
	width: 65vw !important;
}

h4.modal-title {
	background-color: inherit !important;
	color: black !important;
}

/*.modal-body {
    display: inline-block;
}*/

.photo-viewer .attachment-tiles > div {
	width: 100%;
}

.attachment-tiles > div .photo-tile,
.attachment-tiles > div .doc-tile {
	display: inline-block;
}

.attachmentViewer {
	min-width: 1150px;
}

.viewer-body {
	overflow-y: scroll;
}

.attachment-tiles {
	display: flex;
	flex-wrap: wrap;
}

.attachment-tiles .photo-tile {
	width: 33%;
	padding: 70px;
}

.attachment-tiles .doc-tile {
	width: 50%;
	padding: 8px 10px;
}

.attachment-tiles .photo-tile img {
	width: 100%;
}

/* Project detail sections */
.section {
	margin: 10px 0px;
}

.section > h4 {
	background-color: rgb(0, 46, 109);
	color: white;
	padding: 8px;
	border-radius: 2px;
	box-shadow: 0px 6px 7px rgba(0, 0, 127, 0.35);
}

.section.project-detail > div {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.section.project-detail > div > div {
	/*width: 18%;*/
	display: inline-flex;
	margin: 15px 0px 5px 0px;
}

.section.project-detail > div > div span {
	margin-left: 5px;
}

.section.project-detail > div > div > label {
	width: 25%;
}

.section.project-detail > div > div > input,
.section.project-detail > div > div > select {
	width: auto;
	margin-left: 5px;
	margin-top: -5px;
}

.section.project-milestones .milestone-grid {
	width: 100%;
}

.section.project-materials {
	margin-bottom: 150px;
}
/* end project detail*/

/* Report page styles */
input[type="radio"] {
	margin-left: 15px;
}

.report-table {
	margin-top: 10px;
	margin-bottom: 30px;
	overflow: auto;
	/*-moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;*/
}

.report-table .table-header {
	padding: 10px;
}

.report-table .report-line {
	padding: 12px 0px;
	margin: 0;
	/*border-bottom-style: ridge;*/
	border-bottom: 1px solid lightgray;
}

.report-table .report-line input[type="date"] {
	width: calc(100% - 11px);
	border-radius: 2px;
	padding: 2px 6px;
}

.report-line:last-of-type {
	border-bottom-style: hidden;
}

.report-line {
	border-radius: 10px 0px 0px 10px;
}

.report-line .col {
	margin: auto;
}

.red-report-line {
	border-left: solid 10px #d9534f;
}

.red-report-line:hover {
	background-color: rgba(237, 18, 18, 0.2);
}

/*.red-report-line:nth-child(2n+1) {
        background-color: rgba(255, 80, 80, 1);        
        box-shadow: inset 10px 0 10px -10px #000000, inset -10px 0 10px -10px #000000;
    }*/

/*.red-report-line input[type='date'] {       
        color: #ff4d4d;
    }*/

.yellow-report-line {
	border-left: solid 10px rgba(244, 208, 63, 0.8);
}
.yellow-report-line:hover {
	background-color: rgba(255, 215, 97, 0.2);
}
/*.yellow-report-line:nth-child(2n+1) {
        background-color: rgba(255, 215, 97, 1);
        box-shadow: inset 10px 0 10px -10px #000000, inset -10px 0 10px -10px #000000;
    }*/

.default-report-line {
	border-left: solid 10px rgba(27, 87, 230, 0.8);
}

.default-report-line:hover {
	background-color: rgba(100, 150, 120, 0.2);
}
/* Universal width setting classes */

.inline-label {
	/*width: 25%;*/
	font-weight: bold;
	margin-bottom: 5px;
	/*float: left;*/
}

.col-sm-x-tiny {
	width: 4%;
	position: relative;
	float: left;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-sm-tiny {
	width: 6%;
	position: relative;
	float: left;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

/* Ideal for minimally-sized date inputs */
.col-sm-1-minus {
	width: 7%;
	position: relative;
	float: left;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-sm-1-plus {
	width: 10.33%;
	position: relative;
	float: left;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

/* message box */
.state-message {
	font-family: "Montserrat", "Source Sans Pro", "Arvo" !important;
	margin: 0 auto;
	margin-top: 0.5%;
	position: fixed;
	top: 20px;
	left: 42.5%;
	width: 15%;
	text-align: center;
	font-weight: bold;
	color: white;
	background-color: rgb(0, 200, 50);
	opacity: 1;
	padding: 20px;
	border-radius: 3px;
	-webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	z-index: 10000;
}

.state-message .fas {
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
}

.doc-icon {
	display: inline-flex;
	padding: 0px 5px;
	position: relative;
}

.doc-icon .fa-times {
	position: absolute;
	top: -5px;
	right: -3px;
	font-size: 0.7rem;
	color: rgb(130, 130, 130);
}

/* What's New page */

.whats-new-table {
	font-size: 14px;
	padding: 15px;
	border: 1px solid lightgray;
	border-radius: 3px;
	margin-top: 20px;
	-webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

#copyright-modal .modal-dialog {
	width: 50%;
}

#copyright-modal .modal-body {
	overflow-y: scroll;
	max-height: 75vh;
}

#copyright-modal .modal-body .col-sm-offset-1 {
	margin-right: 8.3333333333%;
}

#footer {
	/*height: 50px;*/
	font-size: 9px;
	text-align: center;
	background-color: white;
}

#footer hr {
	margin-top: 0px;
}

.pdf-company-logo {
	position: absolute;
	left: 0px;
	top: 0px;
	height: 50px;
}

.pdf-company-logo > img {
	max-height: 85px;
	width: 194px;
}

.my-col-1 {
	width: 5%;
	padding: 0px 10px;
}

.my-col-2 {
	width: 10%;
	padding: 0px 10px;
}

.my-col-3 {
	width: 15%;
	padding: 0px 10px;
}

.my-col-4 {
	width: 20%;
	padding: 0px 10px;
}

.my-col-5 {
	width: 25%;
	padding: 0px 10px;
}

.my-col-6 {
	width: 30%;
	padding: 0px 10px;
}

.my-col-7 {
	width: 35%;
	padding: 0px 10px;
}

.my-col-8 {
	width: 40%;
	padding: 0px 10px;
}

.my-col-9 {
	width: 45%;
	padding: 0px 10px;
}

.my-col-10 {
	width: 50%;
	padding: 0px 10px;
}

.my-col-11 {
	width: 55%;
	padding: 0px 10px;
}

.my-col-12 {
	width: 60%;
	padding: 0px 10px;
}

.my-col-13 {
	width: 65%;
	padding: 0px 10px;
}

.my-col-14 {
	width: 70%;
	padding: 0px 10px;
}

.my-col-15 {
	width: 75%;
	padding: 0px 10px;
}

.my-col-16 {
	width: 80%;
	padding: 0px 10px;
}

.my-col-17 {
	width: 85%;
	padding: 0px 10px;
}

.my-col-18 {
	width: 90%;
	padding: 0px 10px;
}

.my-col-19 {
	width: 95%;
	padding: 0px 10px;
}

.my-col-20 {
	width: 100%;
	padding: 0px 10px;
}

.no-pad {
	padding: 0px !important;
}

.small-pad {
	padding: 0px 5px !important;
}

.left-tab-yellow {
	border-radius: 10px 0px 0px 10px;
	border-left: solid 10px rgba(244, 208, 63, 0.7);
}

.left-tab-blue {
	border-radius: 10px 0px 0px 10px;
	border-left: solid 10px rgba(27, 87, 230, 0.6);
}

.left-tab-green {
	border-radius: 10px 0px 0px 10px;
	border-left: solid 10px rgba(0, 150, 0, 0.6);
}

.left-tab-purple {
	border-radius: 10px 0px 0px 10px;
	border-left: solid 10px rgba(170, 97, 230, 0.7);
}

.left-tab-red {
	border-radius: 10px 0px 0px 10px;
	border-left: solid 10px rgba(217, 83, 79, 0.7);
}

.custom-scrollbar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	background-color: #f5f5f5;
	visibility: hidden;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background-color: #f5f5f5;
	visibility: hidden;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #ccc;
	visibility: hidden;
}

.custom-scrollbar.cs-blue::-webkit-scrollbar-thumb {
	/*background-color: rgba(27, 87, 230, 0.6);*/
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
	visibility: visible;
}

.guide-modal img {
	border: 1px solid gray;
	margin-bottom: 15px;
	padding: 5px;
	border-radius: 3px;
}

@keyframes slideWidthIn {
	0% {
		width: 100%;
	}

	100% {
		width: 500px;
	}
}

@keyframes slideWidthOut {
	0% {
		width: 100%;
	}

	100% {
		width: 100%;
	}
}
