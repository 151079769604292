/* HOME / LIST PAGE */
.rfi-grid {
}

.rfi-grid-header {
    display: flex;
    padding: 10px 0px 0px 0px;
    /*background-color: rgb(225, 225, 225);*/
    /*border-radius: 5px 5px 0px 0px;*/
    border-bottom: 2px solid lightgray;
    /*left: -10px;*/
    border-left: solid 10px rgba(0, 0, 0, 0);
    border-radius: 13px 13px 0px 13px;
    /*border-top: 2px solid lightgray;*/
}

    /*.rfi-grid-header > div.sortable > label {
        cursor: pointer
    }*/

    .rfi-grid-header .sortable,
    .rfi-grid-header .sortable > label {
        cursor: pointer;
    }

.rfi-grid-filter {
    padding: 10px 0px 10px 0px;
    border-bottom: 2px solid lightgray;
}

    .rfi-grid-filter select.form-control {
        padding: 0px !important;
    }

.rfi-grid .striped-row {
    /*left: -10px;*/
    margin: 0px 0px;
    border-radius: 10px 0px 0px 10px;
    padding: 12px 0px !important;
    font-size: 13px;
    border-bottom: 1px solid lightgray;
    color: black;
    cursor: pointer;
}

    .rfi-grid .striped-row .fa-track-alt {
        color: red;
        z-index: 99;
    }

    .rfi-grid .striped-row.rfi-awaiting {
        border-left: solid 10px rgba(244, 208, 63, 0.8);
    }

    .rfi-grid .striped-row.rfi-answered {
        border-left: solid 10px rgba(27, 87, 230, 0.8);
    }

    .rfi-grid .striped-row.rfi-approved {
        border-left: solid 10px rgba(0, 150, 0, 0.8);
    }

    .rfi-grid .striped-row.rfi-reissued {
        border-left: solid 10px rgba(170, 97, 230, 0.8);
    }

    .rfi-grid .striped-row.rfi-canceled {
        border-left: solid 10px #d9534f;
    }

    .rfi-grid .striped-row > a {
        margin: 0px;
    }

        .rfi-grid .striped-row > a:hover {
            text-decoration: none;
        }

        .rfi-grid .striped-row > a > div {
            color: black;
        }

    .rfi-grid .striped-row:hover {
        background-color: rgb(235, 240, 235);
    }

.rfi-grid-header .fa-plus,
.rfi-grid-header .fa-filter,
.rfi-grid-header .fa-download {
    font-size: 11px;
    top: -6px;
    margin-right: 5px;
}

/* END HOME / LIST PAGE */

/* ADD MODAL */

.rfi-modal {
    width: 70%;
    max-width: 1100px;
}

.modal.show .rfi-modal.modal-dialog {
    width: 75%;
    max-width: 85%;
}

.rfi-modal .modal-body {
    display: grid;
    max-height: 70vh;
    overflow-y: scroll;
    margin-bottom: 20px;
}

.reviewer-line {
    display: flex;
    margin-bottom: 5px;
}

    .reviewer-line .reviewer-select {
        width: 80%;
    }

    .reviewer-line .fa-plus,
    .reviewer-line .fa-minus {
        top: 9px;
        margin-left: 10px;
    }

.check_flex > div:first-of-type {
    display: inline;
}

#schedule_impact_chk,
#cost_impact_chk {
    position: absolute;
    margin-left: 8px;
}

#schedule_impact,
#cost_impact {
    display: inline;
    width: 50%;
}

/* END ADD MODAL */

/* DETAIL MODAL */
/*#rfi-detail {
}

    #rfi-detail .detail-box {
        border: 1px solid lightgray;
    }

        #rfi-detail .detail-box .form-group {
            border-right: 1px solid lightgray;
            padding: 10px;
            margin-bottom: 0px;
        }

    #rfi-detail .reviewer-line-detail {
        width: 150px;
        margin-bottom: 5px;
    }

        #rfi-detail .reviewer-line-detail a {
            float: right
        }*/
/* END DETAIL MODAL */

/* DETAIL PAGE */

.rfi-page .rfi-detail {
    width: 60%;
    border-right: 1px solid lightgray;
    padding-right: 20px;
    max-height: 75vh;
    overflow-y: auto;
}

.rfi-page .event-history-log {
    max-height: calc(75vh - 20px);
}

.rfi-detail {
    /*margin-left: 3%;*/
    /*max-width: 800px;*/
    padding: 0px 0px 0px 15px;
    position: relative;
}

.rfi-top-row {
    display: flex;
    position: relative;
}

.rfi-detail .rfi-top-row .btn.btn-background-hover {
    margin-top: 4px;
    margin-left: 8px;
    font-size: 1.1em;
    height: 30px;
    border-radius: 50%;
}

        .rfi-detail .rfi-top-row .btn:hover {           
            /*font-size: 1.3em;*/
            /*margin-top: 4px;*/
            /*margin-left: 5.5px;*/
        }

.rfi-detail .rfi-body {
    min-width: 600px;
    /*max-width: 1000px;*/
    /*padding-right: calc(100% - 1150px);*/
}

.rfi-page .rfi-logs {
    width: 350px;
    /*margin-left: 10px;*/
    /*margin-top: 25px;*/
    /*margin-left: calc(100% - 1350px);*/
    padding: 0px 10px;
}

/*.rfi-page .rfi-logs .logs-header {
    position: absolute;
    left: 10px;
    font-size: 16px;
    top: -1.5rem;
}
*/
.status-card {
    color: white;
    margin-left: 25px;
    border-radius: 6px;
    padding: 10px 15px;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}

    .status-card.approved {
        background-color: green;
    }

    .status-card.awaiting {
        background-color: rgb(200, 200, 50);
    }

    .status-card.answered {
        background-color: blue;
    }

    .status-card.reissued {
        background-color: rgba(170, 97, 230, 0.8);
    }

    .status-card.canceled {
        background-color: #d9534f;
    }

.rfi-detail-info > div {
    padding: 5px 0px;
}

    .rfi-detail-info > div b {
        display: inline-block;
        width: 150px;
    }

    .rfi-detail-info > div .fa-times {
        margin-left: 5px;
    }

    .rfi-detail .rfi-reviewer-line .fas {
        margin-right: 6px;
    }

.impact-details {
    display: flex;
}

    .impact-details > div {
        margin-right: 50px;
    }

.rfi-question,
.rfi-responses,
/*.rfi-new-response-section,*/
.closing-comment {
    margin-top: 15px;
    width: 60%;
    min-width: 300px;
    /*max-width: 550px;*/
}

.response-name-timestamp {
    font-size: 10px;
    text-align: right;
    margin-bottom: 2px;
}

    .response-name-timestamp span {
        margin-right: 10px;
    }

.reissue-banner {
    background-color: rgba(170, 97, 230, 0.2);
    padding: 10px !important;
    width: 100%;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 20%);
}

.rfi-response-body {
    border-radius: 3px;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    word-wrap: break-word;
}

.new-reviewer-section {
    display: flex;
    width: 500px;
    min-width: 250px;
    /*margin-left: 20px;*/
    /*position: relative;
    clear: both;
    margin: auto;
    top: -30px;
    right: 16%;*/
}

    .new-reviewer-section > div {
        margin-right: 10px;
    }

    .new-reviewer-section input {
        height: 25px !important;
        width: 200px;
    }

    .new-reviewer-section button {
        /*        margin-left: 8px;*/
        font-size: 12px;
        height: 25px;
        bottom: 0px;
        margin-top: 18px;
        padding: 0.2rem 0.5rem;
    }

/*.rfi-detail.print-version .rfi-question {
    width: 65%;
}*/

.rfi-question .rfi-response-body,
.closing-comment .rfi-response-body {
    position: relative;
    background-color: rgb(245, 245, 255);
}

.rfi-response-card {
    position: relative;
    /*margin-bottom: 15px;*/
}

    .rfi-response-card .rfi-response-body {
        position: relative;
        background-color: rgb(245, 255, 245);
    }

        .rfi-response-body .response-text-with-documents {
            padding: 0px 0px 2.5em 0px;
        }

    .rfi-response-card.rejected .rfi-response-body {
        background-color: rgb(255, 245, 245);
    }

    .rfi-response-card .rfi-response-body .response-doc-icons,
    .rfi-question .rfi-response-body .rfi-doc-icons {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 20px;
    }

    .rfi-response-card.editing .response-card-response .rfi-response-body {
        padding-bottom: 75px;
    }

        .rfi-response-card.editing .rfi-response-body .response-doc-icons {
            bottom: 45px;
        }

        .rfi-response-card.editing .rfi-response-body textarea {
            width: 100%;
        }

    .rfi-response-card.editing .response-edit-options {
        position: absolute;
        bottom: 10px;
        right: 20px;
    }

        .rfi-response-card.editing .response-edit-options input[type="file"] {
            width: 200px;
        }

.response-doc-icon {
    cursor: pointer;
}

/*.rfi-responses-for-print {
    float: right;
    width: 65%;
}*/

.rfi-responses
/*.rfi-new-response-section*/ {
    margin-left: 40%;
    width: 60%;
    /*float: right;*/
    /*position: absolute;*/
}

    .rfi-new-response-section textarea {
        margin-bottom: 10px;
    }

    .rfi-new-response-section input[type="file"] {
        display: inline-flex;
    }

    .rfi-new-response-section button {
        margin-right: 10px;
    }

        .rfi-new-response-section button:last-of-type {
            /*float: right;*/
        }

    .rfi-new-response-section .sketch-ref-chk {
        display: flex;
        margin-top: 8px;
    }

        .rfi-new-response-section .sketch-ref-chk input {
            margin-top: 2px;
            margin-left: 5px;
        }

.response-card-response {
    display: flex;
    min-height: 50px;
}

.rfi-response-tabs {
    width: 4%;
}

    .rfi-response-tabs > div {
        padding: 5px;
        height: 50%;
        border-radius: 5px 0px 0px 5px;
        color: white;
        cursor: pointer;
    }

        .rfi-response-tabs > div > span {
            width: 1em;
            margin: auto;
        }

    .rfi-response-tabs > .approve-tab {
        visibility: hidden;
        background-color: #5cb85c;
    }

    .rfi-response-tabs > .reject-tab {
        background-color: #d9534f;
    }

.response-card-response .rfi-response-body {
    width: 96%;
}

.rfi-response-tab {
    width: 4%;
    border-radius: 10px 0px 0px 10px;
}

    .rfi-response-tab.response-approved {
        background-color: #5cb85c;
    }

    .rfi-response-tab.response-rejected {
        background-color: #d9534f;
    }

    .rfi-response-tab span {
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
    }

.rfi-closing-line {
    height: 45px;
    vertical-align: central;
    width: 100%;
    min-width: 300px;
    margin-top: 20px;
    border-radius: 3px;
    padding: 7px 10px;
    background-color: rgba(240, 255, 240, 0.3);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}

    .rfi-closing-line.approved {
        line-height: 2.5;
        background-color: rgba(245, 255, 245, 1);
    }

    .rfi-closing-line span {
        margin-top: 7px;
    }

    .rfi-closing-line button {
        margin-right: 8px;
        float: right;
    }

    .rfi-closing-line .rfi-doc-icons {
        display: inline-flex;
    }

        .rfi-closing-line .rfi-doc-icons a {
            margin-right: 8px;
        }

.closing-comment-box {
    /*width: 30%;*/
    /*margin-left: 15%;*/
    min-width: 250px;
    margin-top: 8px;
}

    .closing-comment-box > div {
        display: flex;
    }

    .closing-comment-box button {
        height: 30px;
        margin-left: 8px;
        margin-top: calc(100% + 1.5em);
    }

.rfi-response-comments {
    position: relative;
}

    .rfi-response-comments input[type="file"] {
        position: absolute;
        right: 0px;
        top: 6px;
        width: 200px;
    }

    .rfi-response-comments .fa-comment {
        font-size: 17px;
        color: gray;
        margin-top: 5px;
    }

.fa-comment.orange {
    color: orange;
}

.comment-count {
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
}

.rfi-response-card .rfi-response-comments .fa-comment {
    /*position: absolute;
    right: 0px;
    bottom: 0px;*/
    margin-left: 5%;
}

.response-comments-list {
    width: 75%;
    margin-left: 25%;
}

    .response-comments-list button {
        height: 30px;
        margin-left: 8px;
        margin-top: auto;
    }

.rfi-response-comment {
    margin-bottom: 10px;
}

    .rfi-response-comment .rfi-response-body {
        background-color: rgba(245, 245, 245, 0.5);
    }

.response-comments-list .new-comment {
    display: flex;
}

/*.rfi-response-comment .new-comment {
        display: flex;
    }*/

#rfi-detail .btn.fa-users,
#rfi-detail .btn.fa-envelope,
#rfi-detail .btn.fa-trash-alt,
#rfi-detail .btn.fa-print,
#rfi-detail .btn.fa-download {
    margin-top: 4px;
    margin-left: 8px;
    font-size: 1.1em;
    height: 30px;
}

#send-external-email {
    display: flex;
    margin-left: 12px;
    margin-top: 5px;
}

    #send-external-email > button {
        height: 25px;
        margin-top: 0.5px;
        font-size: 10px;
        border-radius: 3px;
    }

    #send-external-email > input {
        min-width: 200px;
        max-width: 250px;   
        height: 25px;
        /*margin-right: 8px;*/
    }

#rfi-detail div .fa-download-alt {
    margin-top: 4px;
    margin-left: 12px;
    font-size: 1.1em;
    height: 30px;
}

.rfi-grid .rfi-grid-header .fa-download-alt {
    font-size: 11px;
    top: -6px;
    margin-right: 5px;
}

/* END DETAIL PAGE*/

/* === REISSUE COMPONENT */

.rfi-reissue-component {
}

    .rfi-reissue-component .btn-warning {
        color: black;
        margin-top: 4px;
        margin-left: 8px;
        font-size: 1.1em;
        height: 30px;
        border: 1px solid black;
    }

    .rfi-reissue-component .modal h5 {
        margin-bottom: 0px;
    }

    .rfi-reissue-component .modal .form-group {
        display: flex;
    }

        .rfi-reissue-component .modal .form-group .inline-label {
            min-width: 180px;
        }

/* ===================== */

/* === RFI REISSUE GRID === */

.reissue-requests {
    margin: 0px 0px 0px -15px;
    padding: 5px;
    /*background-color: rgba(170, 97, 230, 0.1);*/
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 5px;
}

    .reissue-requests a {
        text-decoration: none;
        color: black;
    }

    .reissue-requests .grid-line:hover {
        background-color: rgb(235, 240, 235);
    }

/* ======================== */

/* === REISSUE REQUEST DETAIL === */

.reissue-request-detail {
}

    .reissue-request-detail .form-group {
        display: flex;
    }

        .reissue-request-detail .form-group .inline-label {
            width: 150px;
        }

        .reissue-request-detail .form-group textarea {
            width: calc(100% - 150px);
        }

/* ============================== */
/* === REISSUE HISTORY === */
.rfi-reissue-history {
    max-width: 800px;
    border-bottom: 1px solid lightgray;
}

    .rfi-reissue-history .header {
        width: 100%;
        position: relative;
        font-size: 16px;
        border-bottom: 1px solid lightgray;
    }

        .rfi-reissue-history .header .toggle-reissue-history {
            position: absolute;
            right: 10px;
            font-size: 12px;
            top: 3px;
        }

    .rfi-reissue-history .history {
        transition: height 0.6s;
    }

    .rfi-reissue-history.collapsed .hisory {
        display: none;
        height: 0px;
    }

    .rfi-reissue-history .rfi-closing-line {
        margin-bottom: 10px;
    }

/* ======================= */

/* ==== CUSTOM FIELDS ==== */

.modal .custom-field-row {
    display: flex;
    margin-left: -10px;
    padding: 5px 0px;
}

    .modal .custom-field-row label {
        margin-bottom: 0px;
        line-height: 2;
    }

/* ======================= */

/* BEGIN PRINT */

@media print {
    footer {
        display: none !important;
    }

    .rfi-detail {
        -webkit-print-color-adjust: exact !important;
    }

    #rfi-detail .fa-envelope,
    #rfi-detail .rfi-response-comments,
    #rfi-detail .response-doc-icons,
    #rfi-detail .rfi-doc-icons,
    #rfi-detail > a:first-of-type,
    #rfi-detail .fa-print {
        display: none !important;
        visibility: hidden !important;
    }

    #rfi-detail .status-card {
        color: white !important;
        padding: 10px 15px !important;
    }

        #rfi-detail .status-card.awaiting {
            background-color: rgb(200, 200, 50) !important;
        }

        #rfi-detail .status-card.answered {
            background-color: blue !important;
        }

        #rfi-detail .status-card.approved {
            background-color: green !important;
        }

        #rfi-detail .status-card.reissued {
            background-color: purple !important; /*rgba(170, 97, 230, 0.8) !important;*/
            color: red !important;
            height: 100px !important;
        }

    .rfi-question,
    .rfi-responses,
    .closing-comment {
        width: 90mm !important;
        /*background: radial-gradient(circle at 10px 10px, red, #000);
        background: -webkit-radial-gradient(circle at 10px 10px, red, #000);*/
    }

    .rfi-responses {
        margin-left: 6.5cm !important;
        margin-right: 2cm !important;
    }

        .rfi-responses .rfi-response-card {
            page-break-inside: avoid !important;
        }

        .rfi-question .rfi-response-body,
        .rfi-responses .rfi-response-body,
        .closing-comment .rfi-response-body,
        .rfi-closing-line.approved,
        .rfi-detail .status-card {
            border: 1pt solid #ccc;
            /*page-break-inside: avoid !important;*/
        }

    .rfi-question .rfi-response-body,
    .closing-comment .rfi-response-body {
        background-color: rgb(245, 245, 255) !important;
    }

    .rfi-response-card .rfi-response-body,
    .rfi-closing-line.approved {
        position: relative;
        background-color: rgb(245, 255, 245) !important;
    }

    .rfi-response-card.rejected .rfi-response-body {
        background-color: rgb(255, 245, 245) !important;
    }

    .rfi-closing-line {
        width: 15.5cm !important;
    }

    .fa-download-alt {
        display: none !important;
    }
}

/* END PRINT*/

/* === MOBILE STYLES === */

@media (max-width: 767px) {
    .rfi-responses {
        min-width: unset;
        width: 75% !important;
        margin-left: 25% !important;
    }

    #rfi-detail .rfi-body {
        border-right: unset;
    }
}

/* === ============= === */
