.max-350 {
	max-height: 350px !important;
}

.client-color-section {
	display: flex;
	margin-left: 20px;
	position: relative;
}

.client-color-section label {
	font-weight: bold;
	font-size: 14px;
	line-height: 2;
	position: relative;
}

.client-color-section .fa-question,
.client-info-form .fa-question {
	font-size: 10px;
	position: absolute;
}

.client-color-section .fa-question {
	top: 0px;
}

.client-info-form .fa-question {
	top: 5px;
	margin-left: 0px !important;
}

.color-block {
	display: block;
	cursor: pointer;
	height: 1.2em;
	width: 1.2em;
	margin-top: 0.6em;
	margin-left: 8px;
	border-radius: 2px;
}

.client-info-form .color-block {
	margin-left: 0px;
}

.client-color-section .sketch-picker,
.client-info-form .sketch-picker {
	position: absolute;
	top: -30px;
	right: -230px;
	z-index: 10;
	height: 320px;
}

.config-tab-tiles {
	display: flex;
	/*flex-direction: column;*/
	flex-wrap: wrap;
}

.config-tab-tile {
	padding: 10px 15px;
	width: 49%;
	margin: 0 1% 1% 0px;
	border: 1px solid lightgray;
	border-radius: 5px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.config-tab-tile .fa-edit {
	left: unset !important;
}

.config-flex {
	display: flex;
	min-height: 240px;
}

.config-form {
	position: relative;
	height: 100%;
}

.config-form .form-body input,
.config-form .form-body select {
	width: 80% !important;
	margin-right: 30px;
}

.config-form .form-body input[type="checkbox"] {
	width: 25px !important;
}

.config-form .form-footer.to-bottom {
	position: absolute;
	bottom: 0px;
}

.config-new-item-form {
	width: 35%;
	margin-right: 5%;
}

.config-item-list {
	width: 60%;
	max-height: 95%;
	overflow: auto;
	padding: 0px 10px 10px 10px;
}

.max-350 .config-item-list {
	max-height: 300px;
}

.config-new-item-form .form-header,
.config-item-list .form-header {
	margin-bottom: 15px;
}

.project-status-component .form-body {
	width: 100%;
}

/* draggable status cards */
.config-item-line {
	position: relative;
	padding: 10px 8px 10px 8px;
	/*background-color: rgba(147, 179, 163, 1);*/
	border-bottom: 1px solid lightgray;
	/*border: 0.5px solid rgba(20,20,20,0.5);*/
	/*margin-top: 10px;*/
	/*margin-right: 7%;*/
	border-radius: 2px;
	/*width: 98%;*/
	font-size: 13px;
	line-height: 2;
	height: 45px;
	/*box-shadow: rgba(150, 150, 150, 0.75) 0px 0px 7px 0px;*/
}

.config-item-line.editing {
	padding: 10px 8px 10px 3px;
	line-height: 2;
}

.config-item-line:hover {
	/*box-shadow: rgba(100, 100, 100, 0.25) 0px 0px 2px 3px;*/
	background-color: rgb(235, 240, 235);
}

/*.config-item-line > span:first-of-type {
        padding: 8px 0px;
    }*/

.config-item-line input {
	display: initial;
	padding: 3px 6px !important;
	width: 80%;
}

.config-item-line .fa-edit,
.config-item-line .fa-check {
	position: absolute;
	left: auto;
	right: 23px;
	top: 15.5px;
}

.config-item-line .fa-edit:hover,
.config-item-line .fa-check:hover {
	box-shadow: none;
}

.config-item-line .fas.status-remove {
	position: absolute;
	top: 15px;
	right: 5px;
}

.config-item-line .fa-list {
	position: absolute;
	top: 15px;
	right: 47px;
}

.config-item-line .fas.editing {
	padding-top: 2px;
}

/*.drag-clone:last-of-type {
        display: none;
    }*/

.draggable {
	cursor: pointer;
}

.drag-over {
	border: 0.5px dashed black;
}

/* end draggable status cards */

#templatePopup .template_milestones {
	flex-direction: column;
	padding: 10px;
}

#templatePopup .template_milestones > div {
	display: inline-flex;
	width: 50%;
	padding: 5px 0px;
}

#templatePopup .template_milestones > div > input {
	margin-right: 5px;
}

/* DEFAULT PROJECT FOLDERS */

.default-folders {
	font-size: 14px;
	margin-top: 20px;
	/*position: relative;*/
	width: 520px;
	/*margin-left: -20px;*/
}

.default-folders .fa-chevron-down,
.default-folders .fa-chevron-right {
	color: rgba(0, 117, 201, 0.65);
}

.default-folders .fa-folder-open {
	color: #f8d775;
	margin-left: 3px;
	margin-right: 8px !important;
	font-size: 12px !important;
}

.default-folders input {
	/*height: 32px;*/
	font-size: 14px;
	/*margin-left: 3px;*/
	/*margin-top: 5px;*/
	border-radius: 3px;
	padding: 3px;
}

.default-folders .default-folder-section {
	padding-left: 20px;
}

.default-folders .default-folder-line {
	position: relative;
}

.default-folders .default-folder-line .foldername {
	display: flex;
	/*width: 460px;*/
	padding: 6px 2px;
	border-radius: 3px;
}

.default-folder-line .foldername span.fa-chevron-down,
.default-folder-line .foldername span.fa-chevron-right {
	margin-top: 2px;
	padding: 0px 4px;
}

.default-folders .default-folder-line .foldername:hover {
	cursor: pointer;
	background-color: lightgray;
}

.default-folders .default-folder-line .fas {
	margin-right: 3px;
	font-size: 10px;
}

.default-folders .new-folder-line {
	margin-left: 24px;
}

.default-folders .new-folder-line .btn.btn-sm {
	/*padding-top: 3px;*/
}

.default-folders .new-folder-line .btn.btn-sm.btn-blue {
	margin-left: 10px;
}

.default-folders .hover-options {
	position: absolute;
	right: 10px;
	top: 2px;
}

.default-folders .hover-options .btn {
	padding: 4px;
}

.default-folders .hover-options .btn:hover {
	box-shadow: none;
}

/* END DEFAULT PROJECT FOLDERS */

.milestone-import-form input.form-control {
	max-width: 70px;
	padding: 2px 4px !important;
	height: 1.7rem;
	display: initial;
}

.milestone-import-form p {
	margin-bottom: 0px;
	font-size: 9px;
}

/* ==== CUSTOM FIELD TILES ==== */

.custom-fields-config {
	/*display: flex;*/
	display: grid;
	grid-template-columns: repeat(auto-fit, calc(50% - 5px));
	grid-gap: 10px;
}

.custom-fields-tile {
	/*width: calc(50% - 5px);*/
}

.custom-fields-tile .tile-header {
	position: relative;
}

.custom-fields-tile hr {
	margin: 5px 0px 10px 0px;
}

.custom-fields-tile .tile-header .fa-plus {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 1px 5px;
}

.custom-fields-tile .custom-field-line {
	display: flex;
	margin-left: -10px;
	position: relative;
	padding: 5px 0px;
}

.custom-fields-config .custom-field-line:not(.header-row):not(.add-line):hover {
	background-color: rgb(240, 240, 240);
}

.custom-fields-tile .custom-field-line .buttons {
	position: absolute;
	right: 0px;
	top: 0px;
}

.custom-fields-tile .custom-field-line .fa-times {
	position: absolute;
	right: 8px;
	top: 8px;
}
.custom-fields-tile .custom-field-line .fa-edit {
	position: absolute;
	right: 30px;
	left: unset;
	top: 9px;
}

.custom-fields-tile .custom-field-line .fa-edit:active {
	box-shadow: none;
}

.custom-fields-tile .custom-field-line .btn-sm {
	font-size: 12px;
	margin-left: 5px;
	margin-top: 5px;
}

/* ============================ */

/* ===== CHECKLIST CONFIG ===== */

.checklist-home {
	margin-top: 10px;
}

.checklist-template-modal {
}

.checklist-template-modal .modal-body {
	max-height: calc(100vh - 180px);
	overflow-y: auto;
}

.checklist-template-modal .form-group {
	display: flex;
	width: 100%;
	line-height: 1;
}

.checklist-template-modal .joined-list {
	display: block;
	margin: 0px !important;
	padding: 0px !important;
}

.checklist-template-modal .joined-list label {
	font-weight: 900;
}

.checklist-template-modal .joined-list .joined-list-container {
	height: 190px;
}

.checklist-template-modal .form-group .inline-label {
	width: 150px;
	line-height: 2;
}

.checklist-template-modal .form-group input,
.checklist-template-modal .form-group select {
	max-width: calc(100% - 150px);
}

.checklist-template-modal #function-type input,
.checklist-template-modal #checklist-item input {
	max-width: 100%;
}

.checklist-template-modal .new-field-component {
	color: white;
	background-color: #002e6d;
	/*display: flex;*/
	padding: 4px 2px;
	border-radius: 2px;
	width: 112px;
}

.checklist-template-modal .new-field-component.expanded {
	width: fit-content;
	display: flex;
}

.checklist-template-modal .new-field-component .fa-plus,
.checklist-template-modal .new-field-component > span {
	padding: 8px 2px 8px 10px;
}

.checklist-template-modal .new-field-component .new-field-options {
	margin-left: 12px;
}

.checklist-template-modal .new-field-component .new-field-option-box {
	color: black;
	padding: 5px;
	margin: 0px 3px;
	background-color: white;
	border-radius: 2px;
	width: 78px;
	text-align: center;
	cursor: pointer;
}

.checklist-template-modal .checklist-field-info {
	background-color: rgb(240, 240, 240);
	margin: 8px 0px;
	border-radius: 2px;
	padding: 10px 10px 15px 10px;
}

.checklist-template-modal .checklist-field-info .top-row {
	position: relative;
	height: 2rem;
	display: flex;
}

.checklist-template-modal .checklist-field-info .form-group {
	margin-bottom: 0.5rem;
}

.checklist-template-modal .checklist-field-info .checklist-field-buttons {
	position: absolute;
	right: 10px;
	top: -5px;
}

.checklist-template-modal .checklist-field-info .checklist-field-bottom-row {
	display: flex;
}

.checklist-template-modal .checklist-field-info .field-option {
	margin-right: 20px;
}

.checklist-template-modal .checklist-field-info .field-option > span {
	padding-left: 4px;
}

/* ============================ */

/* ==== VENDOR COMPLIANCE ====  */

.field-option-modal .modal-dialog {
	max-width: 600px !important;
}

.field-option-modal .form-group {
	display: flex;
	line-height: 2.5;
	margin-bottom: 5px;
}

.field-option-modal .form-group.add-option {
	margin-bottom: 5px;
}

.field-option-modal .form-group .inline-label {
	min-width: 50px;
}

.field-option-modal .form-group input {
	max-width: 300px;
}

.field-option-modal .form-group .fa-times {
	line-height: 2;
	margin-left: 15px;
	font-size: 1.25em;
}

.field-option-modal .form-group .btn-sm {
	height: 28px;
	margin-left: 10px;
	line-height: 1;
}

/* ============================ */
