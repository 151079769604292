.grid.vendor-grid {

}

.vendor-grid .btn-sm {
    font-size: 10px;
}

    .vendor-grid.grid .grid-body {
        max-height: 70vh;
    }

    .vendor-grid .grid-line,
    .vendor-grid .sv-grid-line {
        color: black;
        text-decoration: none;
    }

        .vendor-grid .grid-line:hover {
            background-color: rgb(235,240,235);
        }

.map-table.vendor-grid {
    margin-right: 10px;
    overflow: auto;
}

    .map-table.vendor-grid .vendor-info {
        display: flex;
        padding: 0px 7px;
        line-height: 2.5;
    }

/* === INFO FORM === */

.vendor-info-form .form-group {
    margin-bottom: 0.5rem;
}

.vendor-joined-list {
    width: calc(100% - 150px);
    margin-left: 5px;
    position: relative;
}

    .vendor-joined-list .fa-check {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .vendor-joined-list .joined-list {
        display: block;
    }

    .vendor-joined-list label {
        display: none;
    }

    .vendor-joined-list .joined-list .button-container {
        left: 41.5%;
    }

    .vendor-joined-list .btn {
        font-size: 10px;
    }

.vendor-joined-list-text {
    position: relative;
    width: calc(100% - 150px);
    line-height: 2.5;
}

    .vendor-joined-list-text .btn.fa-edit {
        position: absolute;
        top: 5px;
        right: 0px;
        left: unset !important;
    }

    .vendor-joined-list .btn.fa-check {
        position: absolute;
        right: 0px;
        bottom: 6px;
        left: unset !important;
    }


/* === INFO MODAL === */

.vendor-info-modal .modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

/* === VENDOR DETAIL */
.vendor-detail .vendor-info-form,
.vendor-detail .vendor-ratings {
    max-width: 850px;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    padding-right: 5px;
    margin-bottom: 50px;
}

    .vendor-detail .hide-in-detail {
        display: none;
    }

/* === RATINGS ==== */

.vendor-ratings {
    position: relative;
}

.rating-title {
    display: flex;
    margin-bottom: 7px;
}

    .rating-title .project-select {
        min-width: 250px;
    }

.rating-notes {
    margin-bottom: 7px;
    line-height: 2;
}

.vendor-rating .rating-notes {
    padding: 0px 5px;
    background-color: rgb(220, 220, 220);
}

.rating-stars .fa-star {
    color: #FFA41C;
}

/* ================ */

/* ==== COMPLIANCE ==== */

.compliance-entry.form-group {
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    padding: 6px 0px 2px 0px;
    border-radius: 2px;
}    

    .compliance-entry.form-group:nth-child(2n+1) {
        background-color: rgb(240, 245, 245);
    }    

    .compliance-entry.form-group .inline-label {
        line-height: 2;
    }

    .compliance-entry.form-group input[type="date"] {
        max-width: 160px;
        padding: 4px !important;
        font-size: 1em !important;
        height: calc(1.5em + 8px);
    }

        .compliance-entry.form-group.expired input[type="date"] {
            color: rgb(220, 25, 25);
        }

    .compliance-entry.form-group input[type="file"] {
        max-width: 200px;
        font-size: 0.9em !important;
    }

    .compliance-entry.form-group input[type="checkbox"] {
        margin-top: 0.4rem;
    }

    .compliance-entry.form-group .doc-icon {
        margin: 0px 10px 0px 5px;
    }

    .compliance-entry.form-group > div {
        display: flex;
    }

    .compliance-entry.form-group .btn {
        font-size: 0.75rem;
        padding: 0px 7px;
        max-height: 1.5rem;      
        margin-left: 5px;
    }

/* ==================== */

/* === F40 Form === */

.f40-form {
}

.f40-form-section {
    border: solid 1px rgb(200, 200, 200);
    margin-bottom: 20px;
}

    .f40-form-section .section-header {
        text-align: center;
        background-color: rgb(200, 200, 200);
        padding: 10px 0px;
        margin-bottom: 10px;
    }

        .f40-form-section .section-header h3 {
            margin: 0px;
        }

    .f40-form-section .f40-form-row {
        padding: 0px 10px;
    }

    .f40-form-section .f40-form-group {
        padding-right: 15px;
    }

        .f40-form-section .f40-form-group .form-label {
            width: 180px;
            vertical-align: bottom;
            padding-top: 0.3em;
        }

        .f40-form-section .f40-form-group input {
            width: calc(100% - 180px);
        }

            .f40-form-section .f40-form-group input[type='radio'] {
                width: 20px;
            }

            .f40-form-section .f40-form-group input[type='checkbox'] {
                width: 16px;
            }

        .f40-form-section .f40-form-group textarea {
            width: calc(100%);
        }

    .f40-form .cert-label {
        width: 550px;
    }


/* ================ */