
.simple-marker,
.round-marker {
    color: white;
    position: absolute;
    background: grey;
    padding: 10px;
    /*display: inline-flex;*/
    text-align: center;
    border-radius: 30%;
    cursor: pointer;
    max-width: 80px;
    overflow-wrap: break-word;
}

    .simple-marker .marker-pointer.btm-left {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 0px;
        right: auto;
        top: auto;
        bottom: -19px;
        border: 19px solid;
        /*border-color: transparent transparent transparent rgb(0, 90, 120);*/
    }

    .simple-marker.marker-hover,
    .round-marker.marker-hover {
        /*background-color: red;*/
        /*border: 2px white solid;*/
        font-size: 14px;
        max-width: 90px;
        z-index: 10001 !important;
    }

.round-marker {
    padding: 5px;
    border-radius: 50%;
    border: 2px solid;
}

    .round-marker.marker-hover {
        padding: 7px;
        box-shadow: rgba(0,0,0,0.4) 3px 3px 5px;
    }

.map-marker-hover-content {
    z-index: 10002 !important;
    width: 1em;
    height: 1em;
}

.marker-cluster {
    color: black;
    position: absolute;
    background-color: lightgreen;
    border: 3px solid green;
    padding: 15px;
    display: inline-flex;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.1;
    cursor: crosshair;
}

    .marker-cluster.cluster-hover {
        font-size: 16px;
        z-index: 10001 !important;
        box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.3);
    }

.map-table {
    width: 100%;
    margin-left: 10px;
    overflow-y: scroll;
    max-height: calc(70vh - 3em);
}

    /*.map-table input {
        width: 98%;
        margin-left: 1%;
        margin-top: 5px;
    }*/

    .map-table .project-row {
        margin: 0px;        
    }      

    .map-table .project-info {
        height: 50px;
        display: block;
    }

        .map-table .project-info > div {
            max-width: 100% !important;
        }

    .map-table .project-info .project-number-label {
        margin: calc(25px - 2em) 0px 0px 0px;
        width: 100%;
    }

    .map-table .project-info .project-detail-label {
        margin: 0px; /*5px 10px 10px 10px;*/
        width: 100%;        
    }

    .map-table .project-row:hover, .map-table-row-hovered {
        /*border-color: #8c8c8c;*/
        /*outline: 5px auto -webkit-focus-ring-color;*/
        background-color: rgba(100, 100, 100, 0.12);
        /*-moz-box-shadow: inset 0 0 5px #000000;
    -webkit-box-shadow: inset 0 0 5px #000000;
    box-shadow: inset 0 0 5px #000000;*/
        /*border: solid 2px rgb(164,213,93)*/
    }