.api-key-grid {
    margin-left: -15px;
}

    .api-key-grid .sv-grid-line {
        line-height: 2;
    }

    .api-key-grid .sv-grid-buttons {
        top: calc(50% - 0.4rem);
    }

    .api-key-grid .fa-eye, 
    .api-key-grid .fa-eye-slash,
    .api-key-grid .fa-clipboard {
        float: right;
        margin-top: 4px;
        font-size: 14px !important;
    }

    .api-key-grid .fas,
    .api-key-grid .far {
        font-size: 12px;
        cursor: pointer;
    }