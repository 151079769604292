/* For tablets */
@media (max-width: 1320px) {

    #loginForm {
        width: 60%;
    }
}



/* screen and main page layouts for 480 viewport and smaller devices 
    does not address smaller components
*/

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        /*padding-top: 50px;*/
    }

    /*general use */
    .app-body {
        font-size: 13px;
        /*margin-top: 20px;*/
    }

    #loginForm {
        width: 85% !important;
    }

    .mobile-hide {
        display: none !important;
    }

    .mobile-show {
        display: inherit;
    }

    .mobile-no-flex {
        display: block !important;
    }

    .min-55 {
        min-width: 55px !important;
    }

    .min-70 {
        min-width: 70px !important;
    }

    .min-85 {
        min-width: 85px !important;        
    }

    .min-110 {
        min-width: 110px !important;
    }

    .min-130 {
        min-width: 130px !important;
    }

    .min-150 {
        min-width: 150px !important;
    }

    .min-179 {
        min-width: 179px;
    }

    .min-200 {
        min-width: 200px;
    }

    .min-220 {
        min-width: 220px;
    }

    .min-85-pct {
        min-width: 85% !important;
    }

    .min-95-pct {
        min-width: 95% !important;
    }

    #dfr-addBtn {
        top: 88vh;
        right: 7vw;
        height: 8vh;
        width: 8vh;
        font-size: 3.2em;
        border-radius: 50%;
        box-shadow: 3px 5px 5px grey;
        position: fixed;
        padding: 0px !important;
        z-index: 1;
    }

    #photo-selector .modal-dialog {
        width: 90%;
    }

    #approve_lockBtn {
        position: fixed;
        top: 87vh;
        border-radius: 50%;
        box-shadow: 3px 5px 5px grey;
        right: 35px;
        height: 8vh;
        width: 8vh;
    }

    #rejectBtn {
        position: fixed;
        top: 78vh;
        border-radius: 50%;
        box-shadow: 3px 5px 5px grey;
        right: 34px;
        height: 8vh;
        width: 8vh;
        z-index: 2;
    }

    #rejectText {
        position: absolute;
        top: 20px;
        right: 9px;
        font-size: 14px;
        font-weight: bolder;
    }

    #submitText {
        position: absolute;
        top: 20px;
        right: 6px;
        font-size: 14px;
        font-weight: bolder;
    }

    #approveText {
        position: absolute;
        top: 8px;
        right: 2px;
        font-size: 11px;
        font-weight: bolder;
        white-space: pre-wrap;
    }

    .project-list .project-buttons {
        right: 5px;
    }

    .project-row .project-info .fa-chevron-down,
    .project-row .project-info .fa-chevron-right {
        right: 16px;
    }

    .dfr_header_row {
        display: inline-flex;
    }

    .dfr_link {
        border-left: 0px !important;
        border-bottom: 1px solid lightgray;
        left: 0px !important;
        height: 50px;
        border-radius: 0px;
    }

    .dfr_rowP_E {
        margin-right: 6% !important;
    }

    .content {
        overflow-x: scroll !important;
        padding: 15px 0px;
    }



    .P_E_E_min-width {
        min-width: 150px;
    }

    .dfr_photoParentDiv{
        margin-top: 7% !important;
    }

    .dfr_upload_btn_wrapper {
        margin-bottom: 8% !important;
    }

    .dfr_textarea_locked {
        width: 100% !important;
    }

    #weatherBody {
        width: 100% !important;
    }

    #tabpanel-Description {
        margin-left: -7% !important;
    }

    hr {
        margin: 10px 0px 20px 0px;
    }

    .mobile-full-width {
        width: 100% !important;
    }

    /* Tabs */


    .tab-links {       
        font-size: 0.85em;
        padding-left: 0px;
    }

        .tab-links .tab-link {
            padding: 0px 5px;
        }

        .content.extra-padding {
            padding-left: 15px;
        }

    .col-sm-2.mobile-full-width {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .col-sm-tiny {
        /*width: 6%;*/
        /*position: relative;*/
        /*float: left;*/
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-sm-1-plus {
        width: 10.33%;
        position: relative;
        float: left;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-sm-1 {
        width: 8.33%;
        position: relative;
        float: left;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-sm-2 {
        width: 16.6667%;
        position: relative;
        float: left;
        min-height: 1px;
        padding: 0px 15px;
    }

    .fa-plus {
        height: 31px;
    }

    /* navigation */
    .main-nav {
        /*max-width: 480px;*/
    }

    .nav > li > div {
        display: flex;
    }
    .nav > li > a, .nav > li > div, .nav > li > div > a {
        height: 40px;
    }

    .nav > li > a, .nav > li > div > a {
        padding: 10px 15px;    
        width: 100%;
    }

    .navbar-nav>li>div>a {
        line-height: 20px;
    }

    .logoutForm.greeting-item {
        display:none;
    }

    /* modals */
    .modal.show .modal-dialog {
        max-width: 90%;
    }

    .modal-content {
        max-width: 100vw;
    }

    .modal-dialog {
        margin: auto;
        margin-top: 60px;
    }

    .modal-body {
        width: 100%;
    }
    
    /*project list page */
    #project-list-page .filter-section {
        width: 100%;
        float: none;
        margin-left: 0px;
    }

    #project-list-page .pageHeader .global-project-filter {
        right: 15px;
        width: 150px;
    }

    #project-list-page .alert-border {
        border: 2px solid rgba(230, 97, 97, 0.8);
    }

    .project-table-header {
        padding-left: 8px;
    }

    .project-info {
        display: flex;
    }

    /*.project-row .project-number {
        width: 60%;
    }*/

    .project-row .project-info div.col-sm-1-plus {
        width: 35vw !important;
    }

    .project-row .project-info div.col-sm-1-minus {
        width: 13vw !important;
    }

    .project-row .project-info .mobile-float, .dfr_link .mobile-float {
        float: right;
        /*background-color: red !important;*/
    }

    #newProjectForm {
        width: 100%;
        max-height: 70vh;
        overflow-y: scroll;
    }

    #newProjectForm .form-group > div {
        padding: 3px 0px;
        width: 100% !important;
    }

        #newProjectForm .row > div {
            margin-top: 10px;
            padding: 0px 15px !important;
        } 

    /* Project Detail page */
    .pageHeader {
        display: flex;
    }

    .pageHeader .col-sm-10 {
        width: 63vw;
    }

    .section.project-detail > div .col-sm-12 {
        display: block
    }

    .section.project-detail .form-group {
        width: 100% !important;
        display: inline-flex;
        margin: 0px 0px;
        padding: 0px 10px;
    }

    /* milestone grid */
    .milestone-grid {
        width: 100%;
        overflow-x: scroll;
    }

    div.milestone-grid-row {
        background-color: inherit !important;
    }

    /*.milestone-grid-row > div {
        display: inline-block;
    }*/
       
    .milestone-grid-row > div.col-sm-1 {
        min-width: 120px;
    }

    .milestone-grid-row > div.col-sm-3 {
        min-width: 260px;
    }

    .milestone-grid-row > div.col-sm-1-plus {
        min-width: 145px;
    }

    .milestone-grid-row > div.col-sm-tiny {
        min-width: 80px;
    }

    /* material grid */
    .material-grid {
        overflow-x: scroll;
    }

    .material-grid-row {
        display: inline-flex;
    }

    .material-grid-row > div.col-sm-1 {
        width: 25vw;
        /*min-width: 120px;*/
    }

    .material-grid-row > div.col-sm-2 {
        min-width: 190px;
    }

    .material-grid-row > div.col-sm-3 {
        min-width: 260px;
    }

    .material-grid-row > div.col-sm-1-plus {
        min-width: 145px;
    }

    .material-grid-row > div.col-sm-tiny {
        width: 20vw;
    }

    .material-grid-row > div.col-sm-x-tiny {
        width: 15vw;
    }

    /* Report page */
    .report-table .filters-row {
        display: none;
    }

    .report-table .table-header, .report-table .report-line {
        display: flex;
        padding: 5px 0px;
    }

        .report-table .report-line {
            min-height: 50px; 
        }

        .report-table .table-header > div, .report-table .report-line > div {
            width: 33%;
        }

            /*.report-table .table-header > div, .report-table .report-line > div.mobile-show:last-child {
                width: 40%;
            }*/

    /* Attachment viewer */
    .attachment-tiles .photo-tile {
        width: 100%;
    }

    .mobile-block {
        display: block !important;
    }

    .mobile-flex-2 {
        /*margin-top: 15px;*/
        display: flex;
    }

    .mobile-flex-2 > div {
        max-width: 50%;
    }

    .notepad {
        max-width: 100%;
    }    

    /* RFI & SUBMITTAL LIST PAGE */

    .rfi-grid-header, .submittal-grid-header {
        height: 40px;
    }

    .rfi-grid .striped-row, .rfi-grid-filter, 
    .submittal-grid .striped-row, .submittal-grid-filter {
        height: 55px;
    }

    /* END RFI LIST PAGE*/

    /* RFI DETAIL */

    .rfi-responses {
        margin-right: 0px !important;
    }

    /* END RFI DETAIL */

    /* File explorer */
    .file-explorer .explorer-container {
        width: 100%;
        margin-right: 0px;
    }

    .file-explorer .file-breadcrumb-trail button.add-folder {
        right: 10px;
    }
}


/* putting this here to make sure bootstrap grid stylings apply on all viewport sizes*/
.col-sm-1.mobile-preserve {
    width: 8.33333%;
}

.col-sm-2.mobile-preserve {
    width: 16.66667%;
}

.col-sm-3.mobile-preserve {
    width: 25%;
}

.col-sm-4.mobile-preserve {
    width: 33.33333%;
}

.col-sm-5.mobile-preserve {
    width: 8.33333%;
}

.col-sm-6.mobile-preserve {
    width: 50%;
}

.col-sm-7.mobile-preserve {
    width: 58.33333%;
}

.col-sm-8.mobile-preserve {
    width: 66.666667%;
}

.col-sm-9.mobile-preserve {
    width: 75%;
}

.col-sm-10.mobile-preserve {
    width: 83.33333%;
}

.col-sm-11.mobile-preserve {
    width: 91.66667%;
}

.col-sm-12.mobile-preserve {
    width: 100%;
}


