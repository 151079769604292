

.logoutForm {
    color:white;
}

.logoutForm > span {
    padding: 10px 15px;
}

.main-nav li .fas {
    margin-right: 10px;
}

.main-nav li a {
    position: relative;
    -webkit-transition-duration: 0.3s; /* Safari */
    transition-duration: 0.3s;
    overflow: hidden;
    /*text-align: center;*/   
}

    .main-nav li a:after {
        content: "";
        display: block;
        position: absolute;
        background: rgb(137,138,141);
        padding-top: 45%;
        padding-left: 100%;
        margin-left: -15px !important;
        margin-top: -30%;
        opacity: 0;
        transition: all 1s;
    }

    .main-nav li a:active:after {
        padding: 0;
        margin: 0;
        opacity: 1;
        transition: 0s
    }

/* Highlighting rules for nav menu items */
    .main-nav li a.active,
    .main-nav li a.active:hover,
    .main-nav li a.active:focus {
        transition-delay: 0.25s;
        /*background-color: rgb(137,138,141);*/
        background-color: rgba(71,71,97,0.65);
        box-shadow: 0 0 3px 2px rgba(200, 200, 200, 0.7);
        -webkit-box-shadow: 0 0 3px 2px rgba(200, 200, 200, 0.7);
        -moz-box-shadow: 0 0 3px 2px rgba(200, 200, 200, 0.7);        
        color: white;
        text-decoration: none;
        /*animation: ripple 1000ms ease-out forwards;*/
    }

.nav-sidebar .fa-xl {
    font-size: 1.75em;
}

ul.navbar-nav, .navbar-nav>li, .navbar-header {
    float: none !important;
}

.navbar-header {
    height: 50px;
}

.navbar-inverse hr {
    margin: 5px 10px 0px 10px;    
    border-color: rgba(250, 250, 250, 1);
}

.navbar-inverse {
    /*background-color: rgb(0,117,201);*/
    background-color: #016d8d;
    padding: 0px !important;
    align-items: initial;
}

    .navbar-inverse .navbar-nav > li a,
    .navbar-inverse .navbar-nav > li a .fas {
        color: white !important;
    }

    /*.navbar-inverse .navbar-nav > li a,*/
    /*.navbar-inverse .navbar-nav*/
    .navbar-inverse .navbar-nav > li a label,
    .navbar-inverse .navbar-brand {
        color: white !important;
        transition: all 1s ease-in;
        -webkit-transition: all 1s ease-in;
        -moz-transition: all 1s ease-in;
        -o-transition: all 1s ease-in;
        opacity: 1;
    }

    .navbar-inverse .navbar-brand {
        position: absolute;
        top: 3px;
        left: 14px;
        width: calc(100% - 58px) !important;
        margin-right: 0px;
    }

    .navbar-brand img {
        height: 20px;
    }

    .navbar-collapse {
        margin-top: -5px;
       
    }
/* Keep the nav menu independent of scrolling and on top of other items */
.main-nav {
    /*position: fixed;*/
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;    
}

.nav > li a:hover {
    text-decoration: none !important;
}

.sidebar-bottom {
    position: absolute;
    bottom: 0px;
    width: 25px;
}

    .sidebar-bottom i {
        padding: 0px 6px;
    }

    .sidebar-bottom i.fa-thumbtack-slash {
        font-size: 13px;
        padding-left: 4px;
    }

@media (max-width: 767px) {
    .main-nav {
        width: 100%;
    }

        .main-nav .navbar-inverse {
            margin-bottom: 0px;
        }

        .main-nav .navbar-header .navbar-toggler {
            position: absolute;
            top: 10px;
            right: 15px;
            border: 1px solid #fff;
            padding: 9px 10px;
            border-radius: 4px;
        }

            .navbar-toggler .icon-bar {
                background-color: #fff;
                display: block;
                width: 12px;
                height: 2px;
                border-radius: 1px;
            }

                .navbar-toggler .icon-bar + .icon-bar {
                    margin-top: 3px;
                }

        .navbar-collapse.collapsed {
            display: none;
        }

        .navbar-collapse {
            margin-top: 50px;
        }

            .navbar-collapse ul li {
                font-size: 14px;
            }
}

@media (min-width: 768px) {
    /* On small screens, convert the nav menu to a vertical sidebar */
    .main-nav {
        z-index: 3;
        height: calc(100vh) !important;
        width: 240px;
        transition: width 0.6s;
        -webkit-transition: width 0.6s;
        -moz-transition: width 0.6s;
        -o-transition: width 0.6s;
    }

    .main-nav a label {
        font-weight: 100;
    }

    .sidebar-toggle {
        position: absolute;
        /*width: 30px;
        height: 30px;*/
        /*border: 1px solid #fff;*/
        color: white;
        top: 10px;
        right: 8px;
        padding: 5px 8px;
        background-color: transparent;
        background-image: none;
        border-radius: 4px;
    }

        .sidebar-toggle:hover {
            color: white;
            font-size: 1.1em;
        }

        .sidebar-toggle .icon-bar {
            background-color: #fff;
            display: block;
            width: 12px;
            height: 2px;
            border-radius: 1px;
        }

        .sidebar-toggle .icon-bar + .icon-bar {
            margin-top: 3px;
        }

    .main-nav.side-collapse {
        width: 50px;
    }

        .main-nav.side-collapse .navbar-brand,
        .main-nav.side-collapse .navbar-nav > li a > label,
        .main-nav.side-collapse label.collapse-hide {
            width: 0px !important;
            padding: 0px !important;
            /*display: none;*/
            transition: all 1s;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -o-transition: all 1s;
            opacity: 0;
        }


        .main-nav .navbar {
            height: calc(100vh);
            border-radius: 0px;
            border-width: 0px;
            border-radius: 0px !important;
            margin-bottom: 0px !important;
            display: block;
        }

        .main-nav .navbar-header {
            font-size: 15px;
            width:100%;
            position: relative;
            height: 50px;
            top: 0px;
        }

    .main-nav .navbar-collapse {
        /*border-top: 1px solid #444;*/
        padding: 0px;
        height: calc(100vh - 50px);
        box-shadow: 3px 0px 5px rgba(150,150,201,0.8);
        -webkit-box-shadow: 3px 0px 5px rgba(150,150,201,1);
        -moz-box-shadow: 3px 0px 5px rgba(150,150,201,0.8);
    }

        .main-nav .navbar ul {
            /*height: 50px !important;
            width: calc(100% - 185px);*/
            margin-top: 5px;
        }

        .main-nav .navbar li {
            /*height: 40px;*/
            font-size: 13px;
            /*margin: 6px 0px;*/
            margin-top: 0px !important;
        }

            .main-nav .navbar li a:hover {
                background-color: rgba(0, 94, 126, 1);
            }

                .main-nav .navbar li a.active:hover {
                    /*background-color: rgb(120, 120, 120);*/
                    background-color: rgba(71,71,97,0.9);
                }

            /*.main-nav .navbar li a:active {
                background-color: red;
            }*/

    .navbar-right li {
        height: 50px;
        padding: 14px 16px 16px 10px !important;
    }

    .main-nav .navbar li label {
        margin-bottom: 0px;
    }

    .main-nav .navbar li > a, .main-nav .navbar li > div > a, .main-nav .navbar li > span {
        padding: 16px 0px 16px 14px !important;
        height: 100% !important;
        cursor: pointer;
        display: block;
    }

    /* Conditionally rendered components are wrapped in divs so they need a little different styling*/
    .main-nav .navbar li > div {
        /*margin-top: 15px;*/
    }

    /*.main-nav .navbar li > div > a {
        padding: 16px 16px 18px 12px !important;
    }*/

        .main-nav .navbar a, main-nav .navbar li > div {
            /* If a menu item's text is too long, truncate it */
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    #logoutForm {
        position: fixed;
        margin-left: 76%;
        z-index: 5
    }

    #longLogoutForm ul li a:hover {
        background-color: inherit !important;
    }

    .logoutForm {
        /*float: right !important;*/
    }

    .company-logo img {
        height: 44px;
        min-width: 100px;
        margin: 3px;
        margin-left: 10px;
    }
}

@keyframes ripple {
    0% {
        transform: translate(-100%, -100%);
    }

    80% {
        transform: translate(-100%, -100%) scale(50);
    }

    100% {
        transform: translate(-100%, -100%) scale(50);
        opacity: 0;
    }
}
