/* button styles */

.btn-x-sm {
    padding: 0.25rem 0.4rem;
    font-size: 0.75rem !important;
    line-height: 1.3;
    border-radius: 0.2rem;
}

.btn-round {
    border-radius: 50%;
    /*margin-right: 1px;*/
}

.btn-background-hover:hover {
    background-color: rgba(100, 100, 100, 0.3);
}

.btn-outline-secondary {
    /*border-bottom: 1px solid #ccc;*/
}

.btn.fas {
    font-weight: 900;
}

/* colors */

.btn-white {
    background-color: rgb(240, 240, 240);
    border: solid 1px rgb(200, 200, 200);
}

    .btn-white:hover {
        background-color: rgb(255, 255, 255);
    }

    .btn-white:active {
        background-color: rgb(220, 220, 220);
    }

    .btn-white:focus {
        outline: none;
    }

.btn-pure-white {
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(200, 200, 200);
}

    .btn-pure-white:hover {
        box-shadow: rgb(200, 200, 200) 0px 0px 10px 0px;
    }

.btn-green {
    background-color: rgb(164, 213, 93);
    border: solid 1px rgb(134, 193, 58);
    /*border-bottom: solid 1px rgb(134,188,73);*/
}

    .btn-green.inverse {
        background-color: inherit;
        border: solid 1px rgb(80, 165, 47);
        color: rgb(80, 165, 47);
    }

    .btn-green:hover {
        background-color: rgb(154, 203, 83);
        color: black;
    }

    .btn-green:active {
        background-color: rgb(144, 193, 73);
        color: black;
    }

    .btn-green:focus {
        outline: none;
    }

.btn-blue {
    background-color: rgb(0, 56, 119);
    /*border-bottom: solid 1px rgb(0, 126, 220);*/
    /*box-shadow: rgb(150, 150, 150) 0px 0.7px 1px 1px;*/
    color: white;
}

    .btn-blue:hover {
        background-color: rgb(0, 40, 106);
        /*border-bottom: solid 2px rgb(0, 100, 180);*/
        box-shadow: rgb(0, 150, 250) 0px 0.7px 1px 1px;
        color: white;
    }

    .btn-blue.btn-disabled:hover,
    .btn-blue:disabled:hover {
        background-color: rgb(0, 56, 119);
        box-shadow: none;
        color: white;
        cursor: not-allowed;
    }

    .btn-blue:active {
        background-color: rgb(0, 25, 80);
        color: white;
        box-shadow: none;
    }

    .btn-blue:focus {
        outline: none;
        color: white;
    }

.btn-red {
    background-color: #cd5c5c;
    color: white;
}

    .btn-red:hover {
        background-color: #bf4e4e;
        color: white;
        box-shadow: rgb(250, 150, 150) 0px 0.7px 1px 1px;
    }

    .btn-red:active {
        background-color: #a64646;
        color: white;
        box-shadow: none;
        outline: none;
    }

    .btn-red:focus {
        outline: none;
        color: white;
    }

.btn-purple {
    color: white;
    background-color: rgba(170, 97, 230, 0.8);
}

    .btn-purple:hover {
        color: white;
        background-color: rgba(170, 97, 230, 1);
        box-shadow: rgb(160, 97, 220) 0px 0.7px 1px 1px;
    }

    .btn-purple:active {
        background-color: rgba(160, 97, 220, 1);
        color: white;
        box-shadow: none;
        outline: none;
    }

    .btn-purple:focus {
        outline: none;
        color: white;
    }

h4 .btn.btn-sm {
    float: right;
    padding: 3px 7px;
    top: -3.5px;
}

/*.btn-disabled:hover {
    background-color: inherit;
    box-shadow: none;
}*/

/* end button styles */
