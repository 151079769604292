.import-home {
}

.import-home .content {
	padding: 15px 0px;
}

.manual-import-form b {
	position: relative;
}

.manual-import-form .form-block b {
	width: 200px;
	display: inline-block;
}

.import-config-list {
}

.import-config-header {
	display: flex;
	position: relative;
	background-color: rgb(235, 235, 235);
	padding: 5px 0px;
	border-radius: 2px;
	border-bottom: 1px solid rgb(200, 200, 200);
}

.import-config-header label {
	margin-bottom: 0px;
	font-weight: 600;
}

.import-config-header .fa-plus {
	position: absolute;
	top: 5px;
	right: 10px;
}

.import-config-line {
	padding: 5px 0px;
	border-bottom: 1px solid rgb(200, 200, 200);
}

.import-config-line .info {
	display: flex;
	/*        padding: 2px 0px;*/
	position: relative;
	/*        border-bottom: 1px solid rgb(215, 215, 215);*/
}

.import-config-line .info .config-icons .fas {
	position: absolute;
	top: 5px;
}

.import-config-line .info .config-icons .fa-trash-alt {
	right: 36px;
}

.import-config-line .info .config-icons .fa-chevron-down,
.import-config-line .info .config-icons .fa-chevron-up {
	right: 10px;
}

.import-config-line .info .config-icons .fa-pencil-alt {
	right: 62px;
}

.import-config-line .info .config-icons .fa-play {
	right: 88px;
}

.import-config-line .switch {
	left: -3px;
}

/* PROJECT COLUMN MAPPING */
.project-import-map {
	margin: 8px 0px;
	padding: 10px;
	background-color: rgb(235, 235, 235);
	border-bottom: 1px solid rgb(200, 200, 200);
}

.project-import-map h5 {
	font-size: 18px;
	margin-bottom: 0px;
}

.project-import-map .mapping-blocks {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 230px));
	grid-gap: 10px;
	margin-top: 5px;
}

.project-import-map label {
	display: block;
	margin-bottom: 0px;
	/*        font-weight: 600;*/
}

.milestone-import-maps {
	padding: 10px;
}

.milestone-import-maps .milestone-map-forms {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 230px));
	grid-gap: 10px;
	margin-top: 5px;
}

.milestone-import-maps .milestone-map-forms .milestone-map-form {
	/*            border-right: 1px solid rgb(200,200,200);*/
}

.milestone-import-maps .milestone-map-forms .milestone-map-form:last-child {
	border-right: 0px;
}

.milestone-import-maps label {
	display: block;
	margin-bottom: 0px;
	/*        font-weight: 600;*/
}

.milestone-import-maps .milestone-map-label {
	font-size: 14px;
	margin-bottom: 5px;
	font-weight: 600;
}

.milestone-import-maps h5 {
	font-size: 18px;
	margin-bottom: 0px;
}

.edit-import-config-modal {
}

.edit-import-config-modal .modal-body .form-group {
	display: flex;
}

.edit-import-config-modal .modal-body .form-group label {
	margin-bottom: 0px;
	width: 240px;
	font-weight: 600;
	line-height: 2.5;
	position: relative;
}

.manual-import-form .fa-question,
.edit-import-config-modal .modal-body .form-group label .fa-question {
	position: absolute;
	top: 3px;
	font-size: 9px;
}

.edit-import-config-modal .modal-body .form-group .form-control {
	width: 400px;
}

.edit-import-config-modal .modal-body .form-group input[type="checkbox"] {
	margin-top: 0.6em;
}

.switch {
	width: 3em !important;
	height: 1.5em !important;
	position: relative;
	display: inline-block;
}

label.switch {
	margin-bottom: 0px;
}

/* hide the input so the slider span lays over the input */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.switch .slider:before {
	position: absolute;
	content: "";
	height: 1em;
	width: 1em;
	left: 0.25em;
	top: 0.25em;
	bottom: 0.25em;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.switch input:checked + .slider {
	background-color: #2196f3;
}

.switch input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
	-webkit-transform: translateX(1.5em);
	-ms-transform: translateX(1.5em);
	transform: translateX(1.5em);
}
.switch input:disabled + .slider:before {
	opacity: 0.35;
}

/* Rounded sliders */
.switch .slider.round {
	border-radius: 1em;
}

.switch .slider.round:before {
	border-radius: 50%;
}

/* EDIT CONFIG MOADL */
.edit-import-config-modal .modal-body {
	max-height: 600px;
	overflow-y: auto;
}

/* TRACKER PIPELINE CONFIGURATION */
.tracker-pipeline {
	padding: 10px;
}

.tracker-pipeline .pipeline-milestone-maps {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 230px));
	grid-gap: 15px;
	margin-top: 5px;
}

.tracker-pipeline .milestone-map-form > div {
	margin: 8px 0px;
}

.pipeline-milestone-maps label {
	display: block;
	margin-bottom: 0px;
	position: relative;
	/*        font-weight: 600;*/
}

.tracker-pipeline .milestone-map-form > div > label {
	display: inline-block;
	width: 110px;
}

.pipeline-milestone-maps .milestone-map-label {
	font-size: 14px;
	margin-bottom: 5px;
	font-weight: 600;
}

.pipeline-milestone-maps h5 {
	font-size: 18px;
	margin-bottom: 0px;
}

.pipeline-milestone-maps .fa-question {
	position: absolute;
	top: 0px;
	font-size: 9px;
}

@keyframes expandDown {
	from {
		height: 0;
	}

	to {
		height: auto;
	}
}
