.dfr_Row_header {
    padding: 10px 0px 0px 0px;
    border-bottom: 2px solid lightgray;
    /*left: -10px;*/
    border-left: solid 10px rgba(0,0,0,0);
    border-radius: 13px 13px 0px 13px;
    margin: 0px 0px;
}

    .dfr_Row_header .fa-plus, .dfr_Row_header .fa-download {
        font-size: 11px;
        top: -6px;
        margin-left: 10px;
    }

    .dfr_Row_header .fa-download {
        height: 26px;
        margin-left: 4px;
    }

.dfr_link {
    /*display: flex;*/
    /*left: -10px;*/
    /*margin: 0px -15px;*/
    padding: 12px 0px !important;
    font-size: 13px;
    color: black;
    border-bottom: 1px solid lightgray;
    /*box-shadow: 1px 1px 3px #606060;*/
    cursor: pointer;
}

    .dfr_link > a.row {
        margin: 0px;
        text-decoration: none;
    }
    
        .dfr_link>a>div {
            color: black;
            min-height: 15px;
        }

    /*.dfr_link label {
        cursor: pointer !important;
    }*/

    .dfr_link:hover {
        /*box-shadow: 1px 5px 7px black !important;
        border-left: 2px groove aliceblue;*/
        background-color: rgb(235, 240, 235);
    }
   
.dfr_link_header {
    padding: 1.4em;
    color: black;
    font-size: 16px;
    display: flex;
}

#dfr_inbox {
    color: rgba(230, 97, 97, 0.8);
}

#dfr_check {

}

.dfr-top-buttons {
    /*float: right;*/
    /*position: absolute;*/
    right: 0px;
    display: flex;
    position: relative;
    height: 30px;
    font-weight: bold;
}

    .dfr-top-buttons > div {
        position: absolute;
    }

    .dfr-top-buttons .fas, .dfr-top-buttons .far {
        border-radius: 50%;
    }

    .dfr-top-buttons .left-buttons .btn {
        margin-right: 5px;        
    }

    .dfr-top-buttons .right-buttons .btn {
        margin-left: 5px;
    }

        .dfr-top-buttons .btn.fa-trash-alt {
            right: 75px;
        }

        .dfr-top-buttons #approve_lockBtn {
            right: 0px;
        }

        .dfr-top-buttons #rejectBtn {
            right: 80px;
        }

.dfr_textarea {
    /*width: 44vw;*/
    /*height: 10em;*/
    max-width: 100%;
    white-space: pre-line;
    white-space: pre-wrap;
}

.dfr_textarea_locked {
    /*width: 44vw;*/
    /*height: 10em;*/
    border: 1px solid #bcbcbc;
    white-space: pre-line;
    white-space: pre-wrap;
    overflow: auto;
}

.dfr_textArea_list {
    overflow: hidden;
    white-space: pre-line;
    white-space: pre-wrap;
}

.dfr_Personnel {
    /*margin: 3% auto;*/
}

.dfr_rowP_E:nth-child(2n) {
    /*background-color: #E0E0E0;*/
}

.dfr_rowP_E:nth-child(2n+1) {
    /*background-color: #F6F6F6;*/
}

.dfr_rowP_E {
    display: inline-flex;
    height: 48px;
    padding: 8px 15px 8px 0px;
    border-bottom: 1px solid rgb(200, 200, 200);
    border-radius: 3px;
}

.dfr_delete_row {
    padding: 17px;
}

.dfr_label {
    width: 55vw;
    display: flex;
    padding-left: 1%;
}

/*.dfr_row_Equipment {
    width: 46vw;
    display: flex;
    align-items: center;
    padding: 6px 0 6px 10px;
}*/

.dfr_label_E {
    width: 43vw;
    display: flex;
    align-items: center;
    padding-left: 1%;
    clear: right;
}

.dfr_header_row {
    display: inline-flex;
    font-weight: bold;
    padding-left: 0px;
    /*margin-bottom: 1%;*/
}

    .dfr_header_row .btn.fa-plus {
        margin-top: -10px;
    }

#lockBtn {
    float: right;
    margin-left: 2%;
    z-index: 100;
}

#approve_lockBtn {    
    z-index: 3;
}

#dfrModal {
    position: absolute;
    height: 100%;
}

/*==================================================================*/
.dfr_myLockButton {
    -moz-box-shadow: inset 0px 1px 0px 0px #fce2c1;
    -webkit-box-shadow: inset 0px 1px 0px 0px #fce2c1;
    box-shadow: inset 0px 1px 0px 0px #fce2c1;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ffc477), color-stop(1, #fb9e25));
    background: -moz-linear-gradient(top, #ffc477 5%, #fb9e25 100%);
    background: -webkit-linear-gradient(top, #ffc477 5%, #fb9e25 100%);
    background: -o-linear-gradient(top, #ffc477 5%, #fb9e25 100%);
    background: -ms-linear-gradient(top, #ffc477 5%, #fb9e25 100%);
    background: linear-gradient(to bottom, #ffc477 5%, #fb9e25 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc477', endColorstr='#fb9e25',GradientType=0);
    background-color: #ffc477;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    border: 1px solid #eeb44f;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #cc9f52;
}

    .dfr_myLockButton:hover {
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #fb9e25), color-stop(1, #ffc477));
        background: -moz-linear-gradient(top, #fb9e25 5%, #ffc477 100%);
        background: -webkit-linear-gradient(top, #fb9e25 5%, #ffc477 100%);
        background: -o-linear-gradient(top, #fb9e25 5%, #ffc477 100%);
        background: -ms-linear-gradient(top, #fb9e25 5%, #ffc477 100%);
        background: linear-gradient(to bottom, #fb9e25 5%, #ffc477 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fb9e25', endColorstr='#ffc477',GradientType=0);
        background-color: #fb9e25;
    }

    .dfr_myLockButton:active {
        position: relative;
        top: 1px;
    }

.dfr_upload_btn_wrapper {
    /*position: absolute;*/    
    font-size: 12px !important;
    padding-left: 20px;
    display:block;
    min-height: 40px;
}

.dfr_fileUpload {
    width: 0px;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    z-index: -10;    
    /*width: 25%;*/
}

label[for="dfr_fileUpload"] {
    margin-bottom: 0px;
}

    .dfr_fileUpload:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;        
    }

    .dfr_fileUpload + label {
        color: white;
        background-color: rgb(0,32,97);
        font-size: 12px !important;
        display: inline-block;
        text-align: center;
        cursor: pointer; /* "hand" cursor */
        /*width: 30%;*/
    }

        .dfr_fileUpload:focus + label,
        .dfr_fileUpload + label:hover {
            background-color: rgb(0,32,97);            
        }

#dfr_save_photo_btn {
    margin-left: 10px;
}

.dfr_Section {
    background-color: rgb(0, 46, 109);
    color: white;
    padding: 8px;
    font-size: 18px;
}

.dfr_modal-text {
    text-align: center;
}

.dfr_AddDFR_btn {
    background-color: rgb(0,32,97);
    color: white;
    position: absolute;
    right: 0;
    font-size: 20px;
    padding: 0 11px !important;
    z-index: 1;
}

.dfr_weather_wrapper {
    display: flex;
}

#darkSky {
    float: right;
    padding-right: 2%;
}

#weatherBody {
    padding: 5px 20px 15px 20px;
    font-size: 14px;
}

#dfr_photo_download_btn {
    float: right;
}

#dfr_visitor_wrapper {
    /*text-align: center;*/
    position: relative;
}

#dfr_visitor_label {

}

#dfr_visitor_input {
    display: flex;
    margin: 0 auto;
    width: 100%;
    height: 100px;
}

#dfr_print {
    float: right;
    z-index: 1;
}

.dfr_printVersion_wrapper {

}

.dfr_printVersion_header {

}

.dfr_printVersion_content {

}

.fa-edit {
    font-size: 9px;
    position: relative;
    left: 6px;
    cursor: pointer;
}

/*    .fa-edit:hover {
        box-shadow: 1px 1px 3px black;
    }*/

    .fa-edit:active {
        box-shadow: 1px 1px 1px black;
    }

#testing {
    background-color: red !important;
    -webkit-print-color-adjust: exact;
}

.dfr_pv_header {
    font-size: 16px;
    font-weight: bold;
    background-color: #bcc79e !important;
    -webkit-print-color-adjust: exact;
}

.dfr_pv_wrapper {
    -webkit-print-color-adjust: exact;
    background-color: aliceblue !important;
}

.dfr_pv_wrapper td, .dfr_pv_wrapper th {
    padding: 7px 5px;
}

.dfr_pv_row:nth-child(2n+1) {
    background-color: #eeeeee !important;
    -webkit-print-color-adjust: exact;
}

.dfr_pv_image_inner_wrapper {
    /*display: inline-flex;*/    
    display: flex;
    border: 1px solid lightgray;
    border-radius: 1px;
    padding: 8px;
    margin: 10px;
    -webkit-print-color-adjust: exact;
}

    .dfr_pv_image_inner_wrapper .photo-description {
        margin-left: 20px;
    }

    .dfr_pv_image {
        /*width: 70px;*/
        height: 400px;
    }

#photo-selector .modal-dialog {
    width:75%;
    max-width: 75%;
}

#photo-selector .modal-body {
    max-height: 75vh;
    overflow-y: scroll;
}

#photo-selector .modal-body > div, .dfr_photoParentDiv {
    /*display: flex;
    justify-content: space-between;
    flex-wrap: wrap;*/
    display: grid;
    grid-template-columns: repeat(auto-fill, 370px);    
    grid-gap: 1.5em;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

    /*#photo-selector .modal-body > div::after, .dfr_photoParentDiv::after {
        content: "";
        flex: auto;
    }*/

.dfr_photoBorder {
    display: inline-block;
    width: 100%;    
    /*margin: 10px;*/
    padding: 10px 20px;    
    text-align: center;
    /*box-shadow: 6px 5px 15px grey;*/
    /*border-radius: 5px;*/
}

    .dfr_photoBorder .fa-check {
        color: green;
        margin-left: 5px;
    }

    .dfr_photoBorder .rotate-icons {
        height: 20px;
    }

    .dfr_photoBorder .fa-redo.counterclockwise {
        float: left;
        transform: rotateY(180deg);
        margin-left: 5px;
    }

    .dfr_photoBorder .fa-redo.clockwise {
        float: right;
        margin-right: 5px;
    }

    #photo-selector .modal-body .dfr_photoBorder {
        box-shadow: 6px 5px 10px grey;
    }


.dfr_ImgTag {      
    width: 330px;
    height: 330px;
}

.dfr-print-section {
    border: 1px solid lightgray;
    border-radius: 1px;
    padding: 12px;
    margin-bottom: 15px;
}

#dfr_searcher {

}

#dfr_searcher .modal-dialog {
    width: 60%;
    max-height: 75vh;
}

    #dfr_searcher .modal-body {  
        max-height:75vh;
        overflow-y: scroll;
    }

#dfr_searcher .search-line {
    display: flex;
    float: right;
}

#dfr_searcher input {
    /*width: 35%;*/
}

#dfr_searcher .btn {
    font-size: 13px;
    margin-left: 8px;
}

    #dfr_searcher .photo-section > div {
        display: grid;
        grid-template-columns: repeat(auto-fill, 250px);
        grid-gap: 1em;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    #dfr_searcher .photo-section .photo-box {
        border: 1px solid lightgray;
        border-radius: 2px;
        padding: 15px;
        margin: 10px;
       
    }


    #dfr_searcher .photo-section img {
        height: 150px;
        /*width: 150px;*/
        width: 100%;
        max-width: 200px;
    }

    #dfr_searcher .report-section > div {
        display: grid;
        grid-template-columns: repeat(auto-fill, 200px);
        grid-gap: 1em;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    #dfr_searcher .report-section a {
        margin-bottom: 20px;
    }

@media print {
    .dfr_pv_image_inner_wrapper, .dfr-print-section {
        page-break-inside: avoid;
    }

        .dfr_pv_image_inner_wrapper img {
            width: 450px;
        }

        .dfr_pv_image_inner_wrapper table {
            margin-left: 20px;
        }

            .dfr_pv_image_inner_wrapper table td span {
                font-size: 13pt;
                padding: 10px;
                margin-bottom: 15px;
            }

    dfr_pv_image {
        height: 400px;
    }

    .print-page-break {
        page-break-after: always;
    }

    .dfr-print-section {
        border: 1px solid lightgray;
        border-radius: 2px;
        padding: 12px;
        margin-bottom: 15px;
        font-size: 13pt;
    }


}
