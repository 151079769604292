/* SUBMITTAL HOME PAGE */
.submittal-grid {
}

.submittal-grid-header {
    display: flex;
    padding: 10px 0px 0px 0px;
    border-bottom: 2px solid lightgray;
    left: 15px;
    border-left: solid 10px rgba(0,0,0,0);
    border-radius: 13px 13px 0px 13px;
}

    .submittal-grid-header .sortable, .submittal-grid-header .sortable > label {
        cursor: pointer;
    }

.submittal-grid-filter {
    display: flex;
    padding: 10px 0px 10px 0px;
    border-bottom: 2px solid lightgray;
}

    .submittal-grid-filter select.form-control {
        padding: 0px !important;
    }

.submittal-grid .striped-row {
    display: flex;
    left: 0px;
    /*margin: 0px -15px;*/
    border-radius: 10px 0px 0px 10px;
    padding: 12px 0px !important;
    font-size: 13px;
    border-bottom: 1px solid lightgray;
    color: black;
    cursor: pointer;
    text-decoration: none;
}

    .submittal-grid .striped-row.submittal-draft {
        border-left: solid 10px rgba(244, 208, 63, 0.8);
    }

    .submittal-grid .striped-row.submittal-submitted {
        border-left: solid 10px rgba(27, 87, 230, 0.8);
    }

    .submittal-grid .striped-row.submittal-approved {
        border-left: solid 10px rgba(0, 150, 0, 0.8);
    }

    .submittal-grid .striped-row.submittal-void {
        border-left: solid 10px rgba(170, 97, 230, 0.8);
    }

    .submittal-grid .striped-row.submittal-rejected {
        border-left: solid 10px #d9534f;
    }

    .submittal-grid .striped-row > a {
        margin: 0px;
    }

        .submittal-grid .striped-row > a > div {
            color: black;
        }

        .submittal-grid .striped-row > a:hover {
            text-decoration: none;
        }

    .submittal-grid .striped-row:hover {
        background-color: rgb(235,240,235)
    }

.submittal-grid-header .fa-plus, .submittal-grid-header .fa-filter,
.submittal-grid-header .fa-download {
    font-size: 11px;
    top: -6px;
    margin-right: 5px;
}

/* END SUBMITTAL HOME PAGE*/

/* SUBMITTAL ADD MODAL */
.submittal-modal {
    min-width: 700px;
    width: 45% !important;
}

    .submittal-modal .modal-body {
        overflow-y: auto;
        max-height: 70vh;
    }

        .submittal-modal .modal-body .row {
            padding: 5px;
        }

            .submittal-modal .modal-body .row div.col-sm-3 {
                padding: 0px 15px !important;
            }
/* END SUBMITTAL ADD MODAL */

/* SUBMITTAL DETAIL */

#submittal-page .submittal-detail {
    width: 75%; /* calc(100% - 350px); */
    border-right: 1px solid lightgray;
    padding-right: 20px;
    min-height: 75vh;
    max-height: 75vh;
    overflow-y: auto;
}

#submittal-page .event-history-log {
    max-height: calc(75vh - 20px);
}

.doc-icons {
    display: flex;    
}

    .doc-icons > a {
        margin-right: 6px;
    }

    .submittal-info .doc-icons {
        padding-top: 5px;
        max-width: 100%;
        overflow-x: auto;
    }

.submittal-detail, #submittal-package-detail {
    margin: 3px 10px;
}

    .submittal-detail .top-row .btn {
        margin-top: 4px;
        margin-left: 8px;
        font-size: 1.1em;
        height: 30px;
        border-radius: 50%;
    }

    .submittal-detail .submittal-info, #submittal-package-detail .submittal-package-info {
        padding: 10px 0px;
        /*width: 55%;*/
        padding: 10px 15px;
        border-radius: 5px;
        margin: 0px 0px 0px 3px;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
    }

    #submittal-package-detail .submittal-package-info {
        width: 55%;
        margin-bottom: 20px;
    }

    .submittal-detail .submittal-body {
        /*width: calc(100% - 350px);*/
        padding-right: 20px;
        /*border-right: 1px solid lightgray;*/
    }

    .submittal-detail .submittal-logs {
        width: 350px;
        margin-left: 10px;
        padding: 0px 10px;
    }

        .submittal-detail .submittal-logs .logs-header {
            position: absolute;
            left: 10px;
            font-size: 16px;
            top: -2em;
        }

    .submittal-detail .submittal-info label,
    #submittal-package-detail .submittal-package-info label {
        line-height: 2;
        margin-bottom: 0px;
    }



        .submittal-detail .submittal-info .row, #submittal-package-detail .submittal-package-info .row {
            padding: 5px 7px;
        }

.submittal-info .submittal-response-doc-container {
    max-width: 160px;
    position: relative;
    min-width: unset !important;
}

            .submittal-info .submittal-response-doc-container:first-of-type {
                border-left: none;
            }

            .submittal-info .submittal-response-doc-container .fa-remove {
                display:none;
                position: absolute;
                right: calc(50% - 27px);
                top: 5px;
                padding: 3px !important;
                border-radius: 50%;
                height: 1.25rem;
                width: 1.25rem;
                line-height: 0.8rem;
            }

                .submittal-info .submittal-response-doc-container:hover .fa-remove {
                    display: unset;
                }

    #submittal-package-detail .package-submittal-grid {
        overflow: auto;
        padding: 15px 20px 15px 35px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
    }

    #submittal-package-detail .info-detail-header {
        padding: 10px;
        background-color: rgb(0, 46, 109);
        color: white;
        font-size: 16px;
        margin-bottom: 20px;
    }

        #submittal-package-detail .info-detail-header .fas {
            margin-left: 6px;
            font-size: 14px;
        }

    #submittal-package-detail .detail-parent {
        display: flex;
        justify-content: space-between;
    }

    #submittal-package-detail .submittal-chart {
        overflow: auto;
        overflow-y: hidden;
        padding: 15px 20px 15px 35px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        width: 42%;
        position: relative;
        margin-bottom: 20px;
    }

.status-card.draft {
    background-color: rgba(244, 208, 63, 0.8);
    color: rgb(128,128,128);
}

.status-card.submitted {
    background-color: rgba(27, 87, 230, 0.8);
}

.status-card.approved {
    background-color: rgba(0, 150, 0, 0.8);
}

.status-card.rejected {
    background-color: rgba(230, 97, 97, 0.8);
}

.status-card.void {
    background-color: rgba(170, 97, 230, 0.8);
}

.accepted-doc-section, .copy-reference-section {
    width: 100%;
    padding-right: 10px;
    margin: 0px 0px 20px 0px;
    padding: 10px;
    border-radius: 5px;
    /*-webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);*/
    /*-moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);*/
    /*box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);*/
}

.accepted-doc-section {
    background-color: rgba(0, 150, 60, 0.1);
}

    .accepted-doc-section hr {
        border-color: rgb(210, 210, 210);
        margin: 10px;
    }

.copy-reference-section {
    background-color: rgba(150, 150, 200, 0.2);
    padding: 10px !important;
}

.accepted-doc-section .docs {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-around;
}

    .accepted-doc-section .docs .accepted-submittal-doc {
        width: 210px;
        font-size: 11px;
    }

.submittal-btn-row {
    display: flex;
}

    .submittal-btn-row button {
        margin-right: 12px;
    }

.submittal-review-section {
    /*width: 55%;*/
}

    .submittal-review-section .btn,
    .submittal-review-section .filterable-select {
        margin-top: 12px;
    }

.submittal-stamp {
    padding: 10px 20px 20px 10px;
    border: 1px solid red;
    color: red;
    margin-bottom: 15px;
}

    .submittal-stamp .review-options {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        min-width: 400px;
    }

        .submittal-stamp .review-options .review-option {
            width: 50%;
            float: left;
            padding: 5px 0px;
        }

    .submittal-stamp .stamp-disclaimer {
        padding: 10px;
    }

.review-submit {
    width: 900px;
    /*min-width: 900px;*/
    display: flex;
    position: relative;
}

    .review-submit textarea {
        width: 650px;
        /*min-width: 300px;*/
    }

    .review-submit button, .review-submit input[type="file"] {
        position: absolute;
        height: 32px;
        bottom: 0px;
    }

    .review-submit button {
        right: 0px;
    }

    .review-submit input[type="file"] {
        left: 665px;
    }

.submittal-response-section {
    position: relative;
    margin-bottom: 15px;
    /*width: calc(45% - 20px);*/
}

    .submittal-response-section .response-detail-modal .form-group {
        display: flex;
    }

    .submittal-response-section .response-detail-modal .form-group label {
        width: 150px;
        min-width: 150px;
    }

    .submittal-response-section .response-detail-modal .form-group > div {
        width: calc(100% - 150px);
    }

    .submittal-response-section .sv-grid-header {
        display: flex;
        background-color: lightgray;
        font-weight: bold;
        border-radius: 5px 5px 0px 0px;
        height: 3rem;
        line-height: 2rem;
    }

        .submittal-response-section .sv-grid-header .fa-chevron-down,
        .submittal-response-section .sv-grid-header .fa-chevron-up {
            font-weight: bold;
            font-size: 1.25em;
        }

    .submittal-response-section .sv-grid-body {
        /*visibility: hidden;*/
    }

        .submittal-response-section .sv-grid-body.expanded {
            /*visibility: visible;*/
        }

    .submittal-response-section .submittal-response-line {
        display: flex;
        padding: 0px 8px;
        height: 0rem;
        line-height: 4rem;
        animation: slideUp 0.4s ease-in-out;
        border-bottom: none;
        
    }
    .submittal-response-section .sv-grid-body.expanded .submittal-response-line {
        height: 4rem;
        animation: slideDown 0.4s ease-in-out;
        /*padding: 8px 8px;*/
        border-bottom: 1px solid lightgray;
        overflow: hidden;
        cursor: pointer;
    }

    .submittal-response-doc-container {
        min-width: 65%;        
        border-left: 1px solid lightgray;
        padding: 0px 6px;
        margin-top: -0.5rem;
    }

        .response-detail-modal .submittal-response-doc-container {
            min-width: 33%;
            width: 33%;
            border-left: none;
        }

        .submittal-response-doc-container:first-of-type {
            border-left: none;
        }

        .submittal-response-doc-container .doc-icon {
            display: block;
            margin-left: calc(50% - 21px);
            margin-top: 0.75rem;
        }

        .submittal-response-doc-container > * {
            /*justify-content: center;*/
            line-height: 1.5rem;
        }
        .submittal-response-doc-container a {
            /*line-height: 1rem;*/
        }

        .submittal-response-doc-container .truncate {
            /*margin-top: -1rem;*/
            line-height: 2rem;
            max-width: 30rem;
        }

    .submittal-response-section .submittal-response-card {
        position: relative;
        height: fit-content;
        padding: 20px;
        border-radius: 5px;
        margin: 10px 3px;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
    }

    .submittal-response-section > .submittal-response-card:first-of-type {
        margin-top: 0px;
    }

    .submittal-response-section .response-list-header {
        padding: 0px 5px;
        font-size: 1.1em;
        border-bottom: 1px solid lightgray;
        margin: 6px 0px;
    }

    .submittal-response-section .response-list {
        max-height: 46vh;
        overflow-y: auto;
    }

    .submittal-response-section .response-list-header .fas {
        margin-left: 5px;
    }

.submittal-response-card .submittal-response-comment {
    margin-bottom: 10px;
}

.submittal-response-card hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.submittal-response-card .doc-icons {
    position: absolute;
    left: 20px;
    bottom: 20px;
}

#select-docs-modal .modal-dialog {
    width: 900px;
}

.submittal-doc-items {
    display: flex;
    flex-wrap: wrap;
}

.submittal-doc-item {
    position: relative;
    width: 210px;
    margin-bottom: 25px;
    text-align: center;
}

    .submittal-doc-item input[type="checkbox"] {
        display: block;
        margin-left: 101px;
        margin-bottom: 5px;
    }

    .submittal-doc-item a {
        margin-top: 10px;
    }


/* END SUBMITTAL DETAIL */

/* MISC */

.submittal-revisions-info {
    margin: 0px 3px 5px 3px;
    width: 100%;
}

.revision-info-line {
    padding: 10px;
    background-color: rgba(244, 208, 63, 0.15);
    margin-bottom: 8px;
}

    .revision-info-line span {
        margin-right: 20px;
    }

    .revision-info-line .response-doc-icon,
    .revision-info-line .doc-icon {
        margin-left: 10px;
    }

/* PRINT DETAILS */

.submittal-print-page button {
    position: absolute;
    top: 20px;
    left: 20px;
}

.submittal-print-coversheet {
    text-align: center;
}

.print-details .row {
    display: flex !important;
    color: blue !important;
    border: 1px solid red !important;
}



.submittal-print-coversheet .print-details {
    padding: 20px;
    border: groove 6px gray;
    font-size: 14px;
    text-align: left;
}

    .submittal-print-coversheet .print-details .row {
        padding: 15px 0px;
    }

        .submittal-print-coversheet .print-details .row .col-sm-6 {
            padding-left: 10px !important;
        }

@media print {
    #hideme {
        display: none;
    }

    .print-details {
        padding: 20pt;
        border: groove 6pt gray;
        border-radius: 3px;
        font-size: 20pt;
        text-align: left;
    }

        .print-details table {
            font-size: 13pt;
        }
}

/* === MOBILE STYLES === */

@media (max-width: 767px) {
    .submittal-info .row > div {
        width: 100%;
        padding-left: 10px;
        padding-right: 5px;
    }

        .submittal-info .row > div:first-of-type label {
            font-weight: bold;
        }

    .review-options .review-option {
        width: 100% !important;
    }
}

/* === ============= === */

@keyframes slideDown {
    0% {
        height: 0px;
        opacity: 0;
        /*padding: 0px 8px;*/
    }

    100% {
        height: 4rem;
        opacity: 1;
        /*padding: 8px 8px;*/
    }
}

@keyframes slideUp {
    0% {
        height: 4rem;
        opacity: 1;
        /*padding: 8px 8px;*/
    }

    100% {
        height: 0rem;
        opacity: 0;
        /*padding: 0px 8px;*/
    }
}
