
#notification-pref .btn-row {
    margin-top: 10px;
}

#notification-pref p, #report-pref p {
    padding: 15px;
}

#report-pref hr {
    width: 100%;
}

.pref-row {
    padding: 10px 0px;
    display: flex;
}

    .pref-row .col-sm-2 {
        min-width: 200px;
    }

    .pref-row .col-sm-1 {
        min-width: 100px;
    }

.grid-body .pref-row:nth-of-type(2n+1) {
    background-color: rgb(235, 235, 235)
}

.pref-row input {
    width: 75%;
}

.show-pass-toggle {
    float: right;
    top: -24px;
    left: -15px;
    cursor: pointer;
}

.account-settings .form-body label {
    margin-bottom: 3px;
}

.account-settings .form-body input.form-control,
.account-settings .form-body select.form-control {
    width: calc(100% - 100px);
}