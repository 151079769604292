/* === CHECKLISTS === */

.project-checklists {
	margin: 0px -5px;
}

.project-checklists .modal .form-group,
.checklist-field .form-group {
	display: flex;
}

.project-checklists .modal .form-group .inline-label,
.checklist-field .form-group .inline-label {
	width: 225px;
	min-width: 225px;
	line-height: 1.5;
}

.project-checklists .modal .form-group > .form-control,
.project-checklists .modal .form-group .filterable-select,
.checklist-field .form-group > .form-control {
	width: calc(100% - 425px);
	min-width: 225px;
}

.project-checklists .modal .form-group .multiline-block {
	display: block;
	width: calc(100%);
}

.project-checklists .modal .form-group .multiline-block > div {
	margin-bottom: 5px;
	position: relative;
}

.project-checklists .modal .form-group .multiline-block .fa-times {
	/*position: absolute;
                left: calc(100% - 415px);
                top: calc(50% - 0.5em);*/
	margin-top: 0.7em;
	margin-left: 6px;
}

.project-checklists .modal .form-group .fa-remove {
	position: absolute;
}

.checklist-field {
	margin-bottom: 10px;
}

.checklist-field .form-group {
	margin-bottom: 0px;
}

.checklist-field input[type="file"] {
	margin-left: 10px;
	font-size: 11px;
	line-height: 1.2;
}

.checklist-field input.form-control {
	height: 24px;
}

.checklist-field .notepad {
}

.checklist-field .notepad .fa-pencil-alt {
	padding: 4px 0.5rem !important;
	position: relative;
}

.modal.checklist-detail .modal-header .form-control {
	width: 500px;
	font-size: 1.25rem !important;
	margin: -2px 0px 6px -7px;
}

.modal.checklist-detail .modal-header .fa-edit {
	font-size: 0.7rem !important;
	top: -10px;
}

.modal.checklist-detail .modal-header .fa-check {
	font-size: 0.8rem !important;
	margin-top: -5px;
}

.modal.checklist-detail .modal-body {
	max-height: calc(100vh - 250px);
	overflow-y: auto;
}

.checklist-field .checklist-checkbox {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.checklist-field .checklist-checkbox > div {
	width: 55px;
}

.checklist-field .checklist-checkbox input {
	/*margin-right: 3px;*/
	vertical-align: bottom;
	margin-bottom: 2px;
}

.checklist-field .checklist-checkbox span {
	margin: 0px 8px 0px 1px;
}

/* ================== */
