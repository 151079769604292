.file-explorer {
    border: 1px solid lightgray;
    padding: 15px;
    border-radius: 3px;
    display: flex;
    font-size: 13px !important;
    max-width: 100vw;
}

    .file-explorer .explorer-container {
        position: relative;
        border: 1px solid lightgray;
        border-radius: 3px;
        padding: 10px;
        margin-right: 15px;
    }

    .file-explorer .fas:hover {
        cursor: auto;
    }

.fa-folder-open {
    color: #f8d775;
}

    .file-explorer .fa-folder-open {
        margin-right: 6px;
    }

.fa-file {
    color: #F4A460;
}
    .file-explorer .fa-file {
        width: 10px;
        margin-right: 11px;
    }

    .file-explorer .file-breadcrumb-trail {
        padding: 5px;
        padding-bottom: 12px;
        border-bottom: 1px solid gray;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
    }

        .file-explorer .file-breadcrumb-trail .fa-folder-open {
            margin-right: 2px;
        }

        .file-explorer .file-breadcrumb-trail .crumb {
            margin-left: 2px;
        }

            .file-explorer .file-breadcrumb-trail .crumb .fas {
                font-size: 9px;
                margin-left: 2px;
                top: 0px;
            }

            .file-explorer .file-breadcrumb-trail .crumb:hover {
                cursor: pointer;
                background-color: lightgray;
            }

        .file-explorer .file-breadcrumb-trail button.add-folder {
            position: absolute;
            right: 190px;
            top: 0px;
        }

        .file-explorer .file-breadcrumb-trail .entry-search {
            height: 20px;
            border-radius: 10px;
            padding: 5px;
            width: 180px;
            vertical-align: middle;
        }

            .file-explorer .file-breadcrumb-trail .entry-search:focus {
                border-radius: 10px;
                outline: none;
                box-shadow: 0px 0px 2px #0066ff;
            }


    .file-explorer .file-explorer-list {
        margin-top: 10px;
        min-height: 158px;
        max-height: 65vh;
        overflow-y: auto;
    }

        .file-explorer .file-explorer-list input {
            margin-top: 5px;
        }

    .file-explorer .file-explorer-line {
        padding: 5px;
        position: relative;
    }

        .file-explorer .file-explorer-line:hover {
            cursor: pointer;
            background-color: lightgray;
        }

        .file-explorer .file-explorer-line .item-name {
            margin-left: 5px;
        }

    .file-explorer .hover-preview-container {
        padding: 10px;
        position: relative;
        border: 1px solid lightgray;
        border-radius: 3px;
        width: 220px;
        height: 225px;
    }

        .file-explorer .hover-preview-container .hover-title {
            text-align: center;
            position: absolute;
            width: 200px;
            color: white;
            background-color: rgba(20, 20, 20, 0.7);
            padding: 3px;
            z-index: 1;
        }

.file-explorer-line .hover-options {
    position: absolute;
    top: 0px;
    right: 10px;
}

    .file-explorer-line .hover-options span.fas.btn {
        padding: 3px 5px !important;
        font-size: 12px;
    }

        .file-explorer-line .hover-options span.fas.btn:hover {
            cursor: pointer;
            box-shadow: unset;
        }

.file-explorer-line .fa-download {
    padding: 0px;
    border: hidden;
}

    .file-explorer-line .fa-download:hover {
        background-color: inherit;
        box-shadow: none;
        color: black;
    }


.file-explorer .new-folder-line {
    display: flex;
    margin-left: 24px;
}

    .file-explorer .new-folder-line input,
    .file-explorer .edit-folder-line input {
        height: 1.6em;
        font-size: 13px;
        border-radius: 3px;
        padding: 3px;
        margin-top: 0px;
    }

    .file-explorer .new-folder-line > div {
        display: flex;
        position: relative;
        margin-right: 15px;        
    }

    .file-explorer .new-folder-line > div > input[type="checkbox"] {
        position: absolute;
        right: -18px;
    }

    .file-explorer .new-folder-line button.btn,
    .file-explorer .edit-folder-line button.btn {
        font-size: 10px;
        margin: -1px 0px 1px 0px;
    }

        .file-explorer .new-folder-line button.btn:hover,
        .file-explorer .edit-folder-line button.btn:hover {
            cursor: pointer;
        }

    .file-explorer .new-folder-line .btn.btn-sm.btn-blue,
    .file-explorer .edit-folder-line .btn.btn-sm.btn-blue {
        margin-left: 14px;
        margin-right: 3px;
    }

.file-explorer .edit-folder-line {
    display: inline-flex;
}

.file-explorer .fas.fa-paste {
    margin-right: 10px;
    cursor: pointer;
    top: -1px;
}
