.work-item-stream {
    padding: 15px 5px;
    left: -15px;
    /*border: 1px solid lightgray;*/
    border-radius: 5px;
}

    .work-item-stream::-webkit-scrollbar {
        width: 40px !important;
    }

    .work-item-stream .work-inbox {
        box-shadow: 0px 0px 11px rgb(163 177 198 / 50%);
        padding: 10px 5px;
        border-radius: 4px;
    }

    .work-item-stream .card-list {
        position: relative;
        /*margin-right: 10px;*/
        margin-top: 10px;
        /*border-top: 1px solid lightgray;*/
        /*padding: 10px 10px;*/
        /*border-right: 1px solid lightgray;*/
        width: 450px;
        overflow-y: auto;
        max-height: calc(75vh - 40px);
        scrollbar-base-color: red;
        /*background-color: rgba(0, 0, 0, 0.02);*/
    }

.work-item-card {
    position: relative;
    padding: 10px;
    /*border-radius: 7px;*/
    /*height: 80px;*/
    height: 70px;
    margin-bottom: 0px;
    /*box-shadow: 0px 0px 3px rgba(0,0,0,0.5);*/
    /*box-shadow: 0px 0px 11px rgb(163 177 198 / 50%);*/
    cursor: pointer;
    /*background-color: rgba(100,100,100,.07);*/
}

    .work-item-card:hover, .work-item-card.selected {
        background-color: rgba(100, 100, 100, 0.12);
    }

    .work-item-card .work-item-title {
        font-size: 13px;
        font-weight: bold;
        margin-top: calc(33px - 2.5em);
        max-width: calc(100% - 150px);
    }

    .work-item-card .work-item-timestamp {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .work-item-card .work-item-link {
        position: absolute;
        right: 10px;
        bottom: 6px;
        visibility: hidden;
    }
        .work-item-link > a,
        .work-item-project > a {
            color: black;
        }

        .work-item-card:hover .work-item-link {
            visibility: visible;
        }

    .work-item-card .work-item-icon {
        position: absolute;
        top: calc(50% - 0.75em);
        color: black;
        font-size: 1.5em;
    }

        .work-item-card .work-item-icon.danger-red {
            color: rgba(217, 83, 79, 0.9);
        }

.work-item-stream .work-item-detail {
    width: calc(100% - 400px);
    max-height: 75vh;
    overflow-y: auto;
    padding: 0px 10px;
    /*box-shadow: 5px 0px 3px rgba(0,0,0,0.5) inset;*/
}

.work-item-stream .work-item-project {
    max-width: 70%;
}

.work-item-stream .work-item-project,
.work-item-stream .work-item-title,
.work-item-stream .work-item-status {
    margin-left: 37px;
}

.work-item-stream .work-item-status {
    font-size: 9px;
}