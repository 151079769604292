#project-list-page .pageHeader .btn,
#project-list-page .pageHeader div.col-sm-2 {
  float: right;
}

#project-list-page .pageHeader .btn {
  /*margin-top: 1px;*/
  line-height: 1.25;
}

#project-list-page .pageHeader .global-project-filter {
  width: 245px;
  position: absolute;
  right: 120px;
  top: 19%;
}

#project-list-page .filter-section .btn.fas {
  /*float: right;*/
  margin-left: 5px;
  font-size: 1em !important;
  left: 0px;
}

#project-list-page .filter-section .btn.fas.btn-green {
  color: white;
}

.projects-kpi-container {
  display: flex;
  min-width: 250px;
}

/*.projects-kpi-container > div {
        min-width: 120px;
    }*/

.project-list-container {
  /*border: 1px solid lightgray;*/
  padding: 6px;
  margin-bottom: 30px;
  border-radius: 5px;
  /*background-color: #E0E5EC;*/
  -webkit-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.25);
  /*box-shadow: 0px 5px 5px 2px rgba(0,0,0,0.25);*/
  box-shadow: 0px 0px 11px rgba(163, 177, 198, 0.5);
  /*box-shadow: 9px 9px 16px rgba(163,177,198,0.6), -9px -9px 16px rgba(255,255,255, 0.5);*/
}

.project-list-container hr {
  width: 100%;
  margin: 5px 0px;
}

.project-list-container .project-list {
  width: 100%;
  max-height: calc(100vh - 290px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.project-list-container.half-height .project-list {
  max-height: calc(50vh - 200px);
}

.project-table-header {
  display: flex;
  float: none !important;
  height: 34px;
  /*background-color: #002e6d;*/
  background-color: #777;
  color: #fff;
  padding: 8px 0px 8px 20px;
  font-size: 13px;
  border-radius: 2px;
  /*box-shadow: 0px 6px 5px rgba(0,0,0,0.3);*/
  margin-bottom: 5px;
  z-index: 1;
}

.project-header-cell {
  /*background-color: #002e6d;*/
  color: white;
}

.project-table-header .btn.fa-plus {
  position: absolute;
  right: 25px;
  font-size: 11px;
  padding: 2px 6px;
  top: 6px;
}

.project-table-header > div .fa-times {
  position: relative;
  /*top: 1px;*/
  right: -5px;
  /*color: white;*/
  font-size: 10px;
  color: rgb(230, 97, 97);
  text-decoration: none;
}

.project-table-header > div .fa-long-arrow-alt-left {
  position: absolute;
  top: 5px;
  left: -7px;
  color: white;
  font-size: 10px;
  text-decoration: none;
}

.project-table-header > div .fa-long-arrow-alt-right {
  /*position: absolute;*/
  /*        top: 1px;*/
  margin-top: 5px;
  margin-left: 1px;
  /*    right: -5px;*/
  color: white;
  font-size: 10px;
  text-decoration: none;
}

.project-row {
  float: none !important;
  clear: left;
  /*margin: 0px 3px;*/
  font-size: 14px;
  border-radius: 3px 0px 0px 3px;
  padding: 8px 0px 6px 0px !important;
  border-bottom: 1px solid lightgray;
  color: black;
  cursor: pointer;
  min-height: 45px;
}

.project-row:hover {
  background-color: rgb(235, 240, 235);
}

.project-row:last-of-type {
  border-bottom: 0px;
}

/*.project-row:nth-child(2n) .project-info {
        background-color: #bbb
    }*/

.project-info {
  display: flex;
  position: relative;
  padding: 6px 0px;
  /*height: 50px;*/
  padding-left: 20px;
  overflow: hidden;
  /*background-color: #eee;*/
  font-size: 12px;
  color: black;
}

.project-row:hover,
.project-info:hover,
.project-row:focus,
.project-info:focus {
  color: black !important;
  text-decoration: none !important;
}

.project-info .fa-flag,
.project-info .fa-exclamation-triangle {
  position: absolute;
  /*top: calc(50% - 6px);*/
  top: 8px;
  left: 10px;
}

.project-row .project-info .fa-chevron-down,
.project-row .project-info .fa-chevron-right {
  position: absolute;
  /*padding: 4px 6px;*/
  /*margin-top: -5px;*/
  top: 3px;
  right: 39px;
}

.project-info .fa-chevron-down:active,
.project-info .fa-chevron-right:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.project-info .fa-star,
.project-info .fa-star-empty {
  position: absolute;
  font-size: 16px;
  color: rgb(228, 175, 22);
  top: 0px;
  right: 0px;
}

.project-info .fa-star:active,
.project-info .fa-star-empty:active {
  color: rgb(208, 160, 40);
}

.project-info.filters-row {
  background-color: inherit;
}

.project-info.filters-row input {
  border-radius: 2px;
  padding: 2px 6px;
  width: 90%;
}

.project-info.filters-row input[type='date'] {
  padding: 3px !important;
}

.project-info > div,
.project-table-header .project-header-cell {
  /*line-height: 2;*/
  padding: 0px 5px;
  position: relative;
  max-width: 20%;
}

.project-info > div.project-number,
.project-table-header > div.project-number {
  width: 110px;
  padding: 0 5px;
}

.project-info > div.project-name,
.project-table-header > div.project-name {
  width: 150px;
  padding: 0 5px;
}

.project-info > div.project-name.editing,
.project-table-header > div.project-name.editing,
.project-info > div.project-number.editing,
.project-table-header > div.project-number.editing {
  color: rgb(150, 130, 130);
}

.project-table-header .project-header-cell.editing {
  display: flex;
  white-space: nowrap;
}

.project-table-header .project-header-cell.editing .header-label {
  text-decoration: underline;
  max-width: calc(100% - 35px);
}

.project-info .project-buttons {
  position: absolute;
  width: 90px;
  padding: 0px 6px;
  top: 15%;
  right: 25px;
}

.project-info .project-buttons .fa-chevron-down,
.project-info .project-buttons .fa-chevron-right {
  /*top: 8px;*/
}

.project-info .project-buttons .fa-star {
  /*top: 6px;*/
}

.p-header-drop-area {
  display: flex;
  width: calc(100% - 350px);
}
/* custom column width classes
    max 10 properties can be selected by user,
    so grid is 20 columns, each property getting at least 2 columns
*/

.project-filter-modal .form-group {
  display: flex;
}

.project-filter-modal .form-group > div:first-of-type {
  width: 150px;
}

.project-filter-modal .form-group > div:nth-of-type(2) {
  width: calc(100% - 150px);
}

.project-filter-modal .date-range-picker .form-control {
  background-color: unset;
}

.project-filter-modal .date-range-picker .fa-times {
  position: absolute;
  right: 7px;
  top: 0.4em;
}

.p-col-1 {
  width: calc((100% - 350px));
}

.p-col-2 {
  width: calc((100% - 350px) / 2);
}

.p-col-3 {
  width: calc((100% - 350px) / 3);
}

.p-col-4 {
  width: calc((100% - 350px) / 4);
}

.p-col-5 {
  width: calc((100% - 350px) / 5);
}

.p-col-6 {
  width: calc((100% - 350px) / 6);
}

.p-col-7 {
  width: calc((100% - 350px) / 7);
}

.p-col-8 {
  width: calc((100% - 350px) / 8);
}

.p-col-9 {
  width: calc((100% - 350px) / 9);
}

.p-col-10 {
  width: calc((100% - 350px) / 10);
}

.p-col-11 {
  width: calc((100% - 350px) / (20 / 11));
}

.p-col-12 {
  width: calc((100% - 350px) / (5 / 3));
}

.p-col-13 {
  width: calc((100% - 350px) / (20 / 13));
}

.p-col-14 {
  width: calc((100% - 350px) / (10 / 7));
}

.p-col-15 {
  width: calc((100% - 350px) / (4 / 3));
}

.p-col-16 {
  width: calc((100% - 350px) / (5 / 4));
}

.p-col-17 {
  width: calc((100% - 350px) / (20 / 17));
}

.p-col-18 {
  width: calc((100% - 350px) / (10 / 9));
}

.p-col-19 {
  width: calc((100% - 350px) / (20 / 19));
}

.p-col-20 {
  width: calc((100% - 350px));
}

.p-h-col-1 {
  width: calc((100%));
}

.p-h-col-2 {
  width: calc((100%) / 2);
}

.p-h-col-3 {
  width: calc((100%) / 3);
}

.p-h-col-4 {
  width: calc((100%) / 4);
}

.p-h-col-5 {
  width: calc((100%) / 5);
}

.p-h-col-6 {
  width: calc((100%) / 6);
}

.p-h-col-7 {
  width: calc((100%) / 7);
}

.p-h-col-8 {
  width: calc((100%) / 8);
}

.p-h-col-9 {
  width: calc((100%) / 9);
}

.p-h-col-10 {
  width: calc((100%) / 10);
}

.p-h-col-11 {
  width: calc((100%) / (20 / 11));
}

.p-h-col-12 {
  width: calc((100%) / (5 / 3));
}

.p-h-col-13 {
  width: calc((100%) / (20 / 13));
}

.p-h-col-14 {
  width: calc((100%) / (10 / 7));
}

.p-h-col-15 {
  width: calc((100%) / (4 / 3));
}

.p-h-col-16 {
  width: calc((100%) / (5 / 4));
}

.p-h-col-17 {
  width: calc((100%) / (20 / 17));
}

.p-h-col-18 {
  width: calc((100%) / (10 / 9));
}

.p-h-col-19 {
  width: calc((100%) / (20 / 19));
}

.p-h-col-20 {
  width: calc((100%));
}

/* END CUSTOM COLUMNS */

.add-project-modal .modal-dialog {
  width: 700px;
}

.add-project-modal .modal-body {
  overflow-y: scroll;
  max-height: 80vh;
}

#newProjectForm .form-group {
  margin-bottom: 5px;
}

#newProjectForm .row {
  margin-bottom: 25px;
}

.project-overview {
  padding: 10px 0px;
}

.project-overview .small-header h4 {
  background-color: rgb(0, 46, 109);
  color: white;
  padding: 8px;
  border-radius: 2px;
  box-shadow: 0px 6px 7px rgba(0, 0, 127, 0.35);
}

.project-detail .flex-row {
  margin-left: 0px !important;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.project-detail .detail-form {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 15px), 500px));
  grid-gap: 15px;
}

.project-detail .flex-row > div.form-group {
  /*        width: 25%;*/
  /*        padding: 8px 0px;*/
  display: flex;
  margin-bottom: 0px;
}

.project-detail .detail-form .form-group .inline-label {
  width: 150px;
  line-height: 2.5;
}

.project-detail .detail-form .form-group .input-holder {
  width: calc(100% - 150px);
}

.project-detail .form-group > div > input,
.project-detail .form-group > div > select {
  /*        width: 80%;*/
  padding: 3px;
}

.project-detail .form-group .userCard {
  /*            width: 90%;*/
}

.pause-modal,
.resume-modal {
  color: black;
}

.pause-modal label,
.resume-modal label {
  font-size: 13px;
}

.pause-alert {
  position: relative;
  color: rgba(255, 158, 42, 1);
}

.pageHeader #project-number-input {
  width: 300px;
  display: inline-block;
  margin-right: 15px;
  font-size: 22px !important;
}

.pageHeader .fa-edit {
  top: -13px;
}

.pause-alert-detail {
  position: fixed;
  color: black;
  padding: 12px;
  font-size: 14px;
  border-radius: 3px;
  top: 30px;
  right: 0px;
  background-color: rgba(255, 158, 42, 1);
  width: 350px;
  margin: 0px;
  font-family: 'Montserrat', 'Source Sans Pro', 'Arvo';
}

.pause-alert-detail > div {
  padding: 3px 0px;
}

.blinker {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  70% {
    opacity: 0;
  }
}

/* TEAM GRID */

.project-team-grid {
  font-size: 13px;
}

.project-team-grid .right-button {
  position: absolute;
  right: 10px;
  top: 4px;
}

.project-team-grid .right-button .btn {
  margin-left: 6px;
  font-size: 11px;
}

.team-grid-line .date-range-picker input {
  max-width: 92%;
}

.team-grid-header {
  border-bottom: 1px solid gray;
}

.team-grid-header label.sortable {
  cursor: pointer;
}

.team-grid-header,
.team-grid-line,
.add-user-line {
  position: relative;
  display: flex;
  padding: 10px 0px;
}

.team-grid-line,
.add-user-line {
  border-bottom: 1px solid lightgray;
  line-height: 2;
}

.team-grid-line .pad-top {
  /*padding-top: 8px;*/
}

.add-user-line .btn-sm {
  padding: 4px 7px;
  width: 29px;
}

.add-vendor-line .filterable-select .react-datalist-input__container {
  /*    position: absolute;
    z-index: 10;
    width: 100%;*/
}
