
@media(max-width: 1650px) and (min-width: 396px) {
    .navbar-header .navbar-brand {
        padding-right: 0px;
    }
    
    /*.navbar-header .navbar-brand img {
        height: 1.45vh !important;
        margin-top: 3px;
    }*/
}