.horizontal-header {
    width: 100%;
    position: relative;
    height: 50px;
    background-color: #002e6d;
}

.horizontal-header .right-menu {
    position: absolute;
    right: 30px; 
    top: 0px;
    display: flex;
}

.horizontal-header .right-icons {
    display: flex;
    /*padding: 15px;*/
}

    .horizontal-header .right-icons > div {
        /*padding: 0px 15px;*/
    }

        .horizontal-header .right-icons > div:hover {
            background-color: rgb(0, 100, 180);
        }

    .horizontal-header .right-icons a,
    .horizontal-header .right-icons > div > div {
        display: inline-block;
        padding: 15px;
        text-decoration: none !important;
        height: 50px;
        color: white;
        cursor: pointer;
    }

        .horizontal-header .right-icons a:hover {
            text-decoration: none !important;
            color: white;
        }
