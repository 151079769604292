.date-range-picker {
}

    .date-range-picker input.form-control {
        width: 100% !important;
    }

    .date-range-picker .fa-times {
        position: relative;
        right: 16px;
        line-height: 2;
        font-size: 11px;
    }

    .date-range-picker .popup-dialog {
        padding: 0px;
        background-color: rgb(229,229,229);
        border-color: transparent rgb(229,229,229) rgb(229,229,229) rgb(229,229,229);
    }

        .date-range-picker .popup-dialog:before {
            border-color: transparent transparent rgb(229,229,229) rgb(229,229,229);
        }

.date-range-picker-dialog {
    display: flex;
    min-width: 600px;
}

    .date-range-picker-dialog .range-options {
        width: 45%;
        /*border-width: 0 1px 0 0;
        border-style: solid;
        border-color: white;*/
    }

        .date-range-picker-dialog .range-options .range-row {
            padding: 12px;
            display: flex;
            border-right: 1px solid white;
            border-bottom: 1px solid white;
        }

        .date-range-picker-dialog .range-options .range-row:last-of-type {            
            border-bottom: 0px;
            padding-left: 5px; 
            padding-right: 5px;
        }

            .date-range-picker-dialog .range-options .range-row:last-of-type span {
                line-height: 30px;
            }

        .date-range-picker-dialog .range-options .range-row.option {
            cursor: pointer;
        }

            .date-range-picker-dialog .range-options .range-row.option.active {
                font-weight: bold;
                background-color: rgb(218, 218, 218);
            }

            .date-range-picker-dialog .range-options .range-row.option:hover {
                background-color: rgb(207, 207, 207);
            }

        .date-range-picker-dialog .range-row input {
            width: 46% !important;
            font-size: 12px;
        }

        /*.date-range-picker-dialog .range-options .range-row.active {            
            background-color: red;
        }*/

    .date-range-picker .calendar {
        padding: 15px 0px 0px 12px;
    }


