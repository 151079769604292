
.punchlist-charts {
    display: flex;  
    justify-content: space-between;
}

    .punchlist-charts > div {
    
    }

    .punchlist-chart-container {
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 3px;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    }

    .punchlist-charts .horizontal-bar-chart {
        width: 47%;
    }

    .punchlist-charts .kpi-chart {
        text-align: center;
        height: 130.5px;
    }

        .punchlist-charts .kpi-chart:first-of-type {
            margin-bottom: 10px;
        }

        .punchlist-charts .kpi-chart h2 {
            margin-top: 25px;
        }

.punchlist-charts .pie-chart {
    width: 30%;
}

.punchlist-charts .punchlist-kpi-column {
    width: 20%;
}

.punchlist-grid .sv-grid-body {
    max-height: calc(100vh - 586.5px);
}