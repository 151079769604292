.transmittal-modal {
}

    .transmittal-modal .modal-body {
        max-height: 70vh;
        overflow-y: auto;
    }

    .transmittal-modal .form-group,
    .transmittal-detail .form-group {
        display: flex;
        line-height: 2.5;
        margin: 10px;
        position: relative;
    }

        .transmittal-modal .form-group b,
        .transmittal-detail .form-group b {
            width: 200px;
        }

        .transmittal-detail .form-group > input,
        .transmittal-detail .form-group > textarea {
            max-width: calc(100% - 200px);
        }

.transmittal-detail .recipient-line {
    position: relative;
}

.transmittal-detail .recipient-line {
    width: 260px;
}

.transmittal-detail .recipient-line,
.transmittal-detail .file-line {
    /*height: 1.25rem;*/
    padding: 0.125rem;
    line-height: 1.25;
    margin-left: -0.125rem;
}

    .transmittal-detail .recipient-line:hover {
        background-color: rgba(245, 245, 250, 1);
    }

    .transmittal-detail .recipient-line .fa-times {
        position: absolute;
        right: 5px;
        top: calc(50% - 0.5em);
    }

    .transmittal-detail .recipient-line .fa-check {
        position: absolute;
        right: 2em;
        top: calc(50% - 0.4em);
        cursor: default;
    }

    /*.transmittal-detail .recipient-line small {
        position: absolute;
        right: -130px;
        top: calc(50% - 0.5em);
    }*/

.transmittal-detail .form-group .btn-sm.btn-blue {
    margin-left: 10px;
    height: 2.5em;
    margin-top: 2em;
}

.transmittal-file-selector .modal-dialog {
    width: 800px;
}

.transmittal-file-selector .selector-line .item-row {
    padding: 6px 0px;
    margin: 2px 0px;
}

.transmittal-file-selector .fa-folder-open,
.transmittal-file-selector .fa-file {
    margin: 0px 8px;
    font-size: 1.5em;
}


.transmittal-file-selector input[type="checkbox"] {
    font-size: 1em;
    margin-left: 8px;
}

.transmittal-file-selector .selector-line .fa-chevron-down,
.transmittal-file-selector .selector-line .fa-chevron-right {
    color: rgb(180, 180, 180);
}

.transmittals-grid {
    margin-left: -10px;
}

    .transmittals-grid .sv-grid-header {
        padding: 8px 0px;
        width: calc(100% - 27px);
        margin-left: 10px;
    }

    .transmittals-grid .sv-grid-body {
        /*overflow-x: visible !important;*/
        /* overflow-y: scroll;
    max-height: 65vh;*/
    }

    .transmittals-grid .sv-grid-line {
        /* left: -10px; */
        margin-right: 15px;
        position: relative;
        /*margin-left: 10px;*/
    }        

        .transmittals-grid .sv-grid-line.half-review {
            margin-left: 10px;
        }

    .transmittals-grid .transmittal-review-progress-bar {
        border-radius: 10px 0 0 10px;
        position: absolute;
        top: 0px;
        left: -10px;
        width: 10px;
        height: 33px;
    }

.transmittal-page-detail {
    width: 60%;
    min-width: 500px;
}

.transmittal-log {
    margin-left: 10px;
    padding: 0px 10px;
    margin-top: 4rem;
}

    .transmittal-log .log-header {
        position: absolute;
        left: 10px;
        font-size: 16px;
        top: -2em;
    }

.transmittal-comments {
    max-width: 500px;
    padding: 10px;
}

    .transmittal-comments h5 {
        font-size: 18px;
    }

    .transmittal-comments .recipient-comment {
        padding: 8px 0px;
        position: relative;
        margin: 8px 0px;
    }

        .transmittal-comments .recipient-comment .commenter-name {
            position: absolute;
            right: 5px;
            top: -9px;
            font-size: 11px;
        }

        .transmittal-comments .recipient-comment .comment {
            padding: 10px;
            background-color: rgb(230, 230, 230);
            border-radius: 2px;
        }

@media (max-width: 767px) {
    .transmittal-log {
        display: none;
    }

    .transmittal-page-detail {
        width: 100%;
    }
}
